import React from "react";
import useColorScheme from "../hooks/useColorScheme";
import Themes from "../Themes";

export default() => {
    const theme = Themes[useColorScheme()];

    const localStyles = {
        container: {
            width: 1,
            height: 40,
            marginLeft: 10,
            marginRight: 20,
            backgroundColor: theme.colors.lightBorder
        }
    };

    return (
        <div style={localStyles.container}></div>
    );
};