import React from "react";
import '../spinner.css';

export default({spinning, type, size}) => {
    if (!spinning) {
        return null;
    }

    var classes = "lds-ring";

    if (type) {
        classes += ` lds-ring-${type}`;
    }

    if (size && size === "small") {
        classes += ` lds-ring-small`;
    }

    return (
        <div className={classes}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    );
};