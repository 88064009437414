import React, { useEffect, useState } from "react";
import Modal from "../components/Modal";
import useColorScheme from "../hooks/useColorScheme";
import Themes from "../Themes";

export default({visible, exampleData, onSetExampleData, onHide}) => {

    const theme = Themes[useColorScheme()];
    const [json, setJson] = useState("");

    useEffect(() => {
        if (visible) {
            if (exampleData) {
                setJson(JSON.stringify(exampleData, null, "\t"));
            } else {
                setJson("");
            }
        }
    }, [visible]);

    const localStyles = {
        textArea: {
            height: 300,
            width: "100%",
            outline: "none",
            marginTop: 20,
            borderRadius: 6,
            padding: 20,
            borderStyle: "solid",
            boxSizing: "border-box",
            borderColor: theme.colors.lightBorder
        }
    };

    const onChangeJson = (updated) => {
        setJson(updated);
    };

    const renderContent = () => {
        return (
            <div>
                <div>Here you can provide example data to show in your scenes</div>
                <textarea style={localStyles.textArea} value={json} onChange={(evt) => onChangeJson(evt.target.value)} spellCheck={false}></textarea>
            </div>
        );
    }

    const isValidJson = () => {
        if (json.trim().length === 0) {
            return true;
        }

        try {
            JSON.parse(json);
            return true;
        } catch (e) {
            return false;
        }
    }

    const saveData = () => {
        onSetExampleData(JSON.parse(json));
        onHide();
    };

    return (
        <Modal
            visible={visible}
            onHide={onHide}
            height="80%"
            width="80%"
            title="Example Data"
            buttons={{
                left: [
                    {
                        id: "close",
                        label: "Close",
                        width: 80,
                        onClick: () => onHide()
                    }
                ],
                right: [
                    {
                        id: "save",
                        label: "Save",
                        width: 80,
                        disabled: !isValidJson(),
                        onClick: () => saveData()
                    }
                ]

            }}
            renderContent={() => renderContent()}
        />
    );
};