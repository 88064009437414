import React from "react";

export default({active, onPick, canvas}) => {
    if (!active) {
        return null;
    }

    const localStyles = {
        container: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            cursor: "grab"
        }
    };
    
    const pick = (evt) => {
        evt.stopPropagation();
        onPick(evt.screenX, evt.screenY);
    };

    const renderImage = () => {
        if (!canvas) {
            return null;
        }

        return (
            <img 
                alt="Select color"
                src={canvas.toDataURL()} 
                width={window.innerWidth} 
                onClick={(evt) => pick(evt)}
                height={window.innerHeight}/>
        );
    };

    return (
        <div style={localStyles.container} >
            {renderImage()}
        </div>
    );
};