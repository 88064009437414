const PROPERTY_TYPES = {
    color: "color",
    text: "text",
    alignment: "alignment",
    url: "url"
};

const intParser = (value) => {
    if (!value) {
        return null;
    }

    try {
        return parseInt(value)
    } catch (e) {
        return null;
    }
};

const floatParser = (value) => {
    if (!value) {
        return null;
    }

    if (value.length > 0 && value.charAt(value.length - 1) === ".") {
        // User is still typing number (i.e. has a trailing .);
        return value;
    }

    try {
        return parseFloat(value)
    } catch (e) {
        return null;
    }
};

const PROPERTIES = {
    value: {
        id: "value",
        title: "Value",
        type: PROPERTY_TYPES.text
    },
    width: {
        id: "style.width",
        title: "Width",
        type: PROPERTY_TYPES.text,
        parse: floatParser
    },
    height: {
        id: "style.height",
        title: "Height",
        type: PROPERTY_TYPES.text,
        parse: floatParser
    },
    x: {
        id: "position.x",
        title: "x",
        type: PROPERTY_TYPES.text,
        parse: floatParser
    },
    y: {
        id: "position.y",
        title: "y",
        type: PROPERTY_TYPES.text,
        parse: floatParser
    },
    backgroundColor: {
        id: "style.backgroundColor",
        title: "Background Color",
        type: PROPERTY_TYPES.color
    },
    color: {
        id: "style.color",
        title: "Color",
        type: PROPERTY_TYPES.color
    },
    borderColor: {
        id: "style.borderColor",
        title: "Border Color",
        type: PROPERTY_TYPES.color
    },
    borderWidth: {
        id: "style.borderWidth",
        title: "Border Width",
        type: PROPERTY_TYPES.text,
        parse: intParser
    },
    textAlignment: {
        id: "style.textAlign",
        title: "Text Alignment",
        type: PROPERTY_TYPES.alignment
    },
    fontSize: {
        id: "style.fontSize",
        title: "Font Size",
        type: PROPERTY_TYPES.text,
        parse: intParser
    },
    fontFamily: {
        id: "style.fontFamily",
        title: "Font Family",
        type: PROPERTY_TYPES.text
    },
    fontWeight: {
        id: "style.fontWeight",
        title: "Font Weight",
        type: PROPERTY_TYPES.text,
        parse: intParser
    },
    borderRadius: {
        id: "style.borderRadius",
        title: "Border Radius",
        type: PROPERTY_TYPES.text,
        parse: intParser
    },
    appearDelay: {
        id: "appearDelay",
        title: "Appear Delay",
        placeholder: "0s",
        type: PROPERTY_TYPES.text,
        parse: floatParser
    },
    leaveDelay: {
        id: "leaveDelay",
        title: "Leave Delay",
        placeholder: "0s",
        type: PROPERTY_TYPES.text,
        parse: floatParser
    },
    image: {
        "id": "image",
        title: "Image",
        type: PROPERTY_TYPES.url
    }
};

const STANDARD_SIZING_PROPERTIES = [
    PROPERTIES.width,
    PROPERTIES.height,
    PROPERTIES.x,
    PROPERTIES.y
];

const STANDARD_SHAPE_PROPERTIES = [
    PROPERTIES.backgroundColor,
    PROPERTIES.borderColor,
    PROPERTIES.borderWidth
].concat(STANDARD_SIZING_PROPERTIES)

const TYPES = {
    circle: {
        id: "circle",
        title: "Circle",
        properties: STANDARD_SHAPE_PROPERTIES.concat([

        ])
    },
    image: {
        id: "image",
        title: "Image",
        properties: [PROPERTIES.image, PROPERTIES.borderRadius].concat(STANDARD_SIZING_PROPERTIES)
    },
    rectangle: {
        id: "rectangle",
        title: "Rectangle",
        properties: STANDARD_SHAPE_PROPERTIES.concat([
            PROPERTIES.borderRadius
        ])
    },
    text: {
        id: "text",
        title: "Text",
        properties: [
            PROPERTIES.value,
            PROPERTIES.color,
            PROPERTIES.textAlignment,
            PROPERTIES.fontFamily,
            PROPERTIES.fontSize,
            PROPERTIES.fontWeight
        ].concat(STANDARD_SIZING_PROPERTIES)
    }
};

export default {
    TYPES: TYPES,
    PROPERTIES: PROPERTIES,
    PROPERTY_TYPES: PROPERTY_TYPES,
    
    getPropertyValueFromObject(obj, propertyId) {
        if (propertyId.indexOf(".") >= 0) {
            var currentObj = obj;

            var splitPath = propertyId.split(".");

            for (var i = 0; i < splitPath.length -1; i++) {
                if (!currentObj[splitPath[i]]) {
                    return null;
                }
                currentObj = currentObj[splitPath[i]];
            }

            return currentObj[splitPath[splitPath.length - 1]];
        }

        return obj[propertyId];
    },

    setPropertyValueOnObject(obj, propertyId, value) {
        var updatedObj = Object.assign({}, obj);

        if (propertyId.indexOf(".") >= 0) {
            var currentObj = obj;

            var splitPath = propertyId.split(".");

            for (var i = 0; i < splitPath.length -1; i++) {
                if (!currentObj[splitPath[i]]) {
                    currentObj[splitPath[i]] = {};
                }
                currentObj = currentObj[splitPath[i]];
            }

            currentObj[splitPath[splitPath.length - 1]] = value;

        } else {
            updatedObj[propertyId] = value;
        }

        return updatedObj;
    }


};