import React from "react";
import Card from "./Card";
import SceneEditor from "./SceneEditor";

export default({scene, project, onClick, exampleData}) => {

    const localStyles = {
        container: {
            width: 200,
            height: 120,
            diplay: "flex",
            alignItems: "center",
            justifyContent: "center"
        },
        title: {
            marginBottom: 15
        }
    };

    return (
        <Card onClick={onClick}>
            <div style={localStyles.container}>
                <SceneEditor
                    scene={scene}
                    objects={scene.data.layout}
                    ordering={scene.data.ordering}
                    project={project}
                    fitWidth={200}
                    exampleData={exampleData}
                    readOnly={true}
                    zoom={0.5}
                />
            </div>
        </Card>
    );
};