import * as React from "react";

function SvgJsonIcon(props) {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18 0a6 6 0 016 6v12a6 6 0 01-6 6H6a6 6 0 01-6-6V6a6 6 0 016-6h12zM9.501 5.142h-.388c-.679 0-1.204.06-1.575.18-.37.12-.673.313-.908.582a1.928 1.928 0 00-.443 1c-.061.398-.092 1.01-.092 1.834 0 1.084-.134 1.781-.402 2.091-.24.276-.582.431-1.029.465l-.173.008v1.758c.542.02.911.145 1.106.377.196.232.328.487.396.765s.102.916.102 1.912c0 .903.096 1.557.286 1.963.19.405.498.697.923.875.378.158.896.246 1.555.264l.254.003h.38v-1.758c-.595 0-.976-.052-1.142-.157a.68.68 0 01-.28-.348l-.035-.117-.11-2.131c-.034-.689-.15-1.216-.347-1.583-.198-.366-.514-.68-.949-.944.46-.293.773-.6.941-.92.169-.32.276-.655.323-1.007.046-.351.084-1.113.113-2.285.01-.425.084-.71.223-.857.127-.133.489-.205 1.085-.218l.186-.001v-1.75zm5.379 0h-.389v1.75c.61 0 .99.045 1.14.132.148.088.242.193.281.315.04.122.074.605.103 1.447.03.842.073 1.418.132 1.728.058.31.17.597.337.861.166.264.459.532.879.806-.396.23-.685.498-.868.805a2.881 2.881 0 00-.367.993c-.06.354-.106 1.156-.135 2.406-.01.434-.092.722-.245.864-.139.127-.49.198-1.053.21l-.196.002v1.758h.388c.679 0 1.204-.06 1.575-.18.37-.119.673-.313.908-.581.234-.269.38-.602.44-1 .058-.398.087-1.012.087-1.842 0-1.074.134-1.768.403-2.08.239-.278.582-.434 1.029-.47l.172-.006v-1.758c-.532-.02-.895-.14-1.087-.363a1.9 1.9 0 01-.403-.736c-.076-.268-.114-.92-.114-1.955 0-.904-.095-1.558-.285-1.963a1.715 1.715 0 00-.923-.876c-.378-.158-.896-.246-1.556-.264l-.253-.003z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgJsonIcon;

