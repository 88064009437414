import React, { useEffect, useState } from "react";
import Loader from "../components/Loader";
import useColorScheme from "../hooks/useColorScheme";
import ProjectServices from "../services/ProjectServices";
import Themes from "../Themes";
import {useParams, useHistory} from "react-router-dom";
import SceneServices from "../services/SceneServices";
import ScenesList from "../components/ScenesList";
import SectionTitle from "../components/SectionTitle";
import HeaderBar from "../components/HeaderBar";
import ScenePreview from "../components/ScenePreview";
import ExampleDataModal from "../modals/ExampleDataModal";

export default() => {

    const theme = Themes[useColorScheme()];

    const [project, setProject] = useState(null);
    const [loading, setLoading] = useState(true);
    const [scenes, setScenes] = useState(null);
    const [currentSceneIndex, setCurrentSceneIndex] = useState(null);
    const [playing, setPlaying] = useState(false);
    const [exampleData, setExampleData] = useState(null);
    const [showExampleDataModal, setShowExampleDataModal] = useState(false);
    
    const {id} = useParams();
    const history = useHistory();

    useEffect(() => {
        reload(); 
    }, []); //eslint-disable-line

    const reload = async() => {
        const loadedProject = await ProjectServices.loadProject(id);
        setProject(loadedProject);

        const loadedScenes = await SceneServices.listScenesForProject(id);
        setScenes(loadedScenes);

        await loadExampleData(loadedProject);

        setLoading(false);
    };

    const loadExampleData = async(project) => {
        if (project) {
            if (localStorage[`${project.id}_example_data`]) {
                try {
                    setExampleData(JSON.parse(localStorage[`${project.id}_example_data`]));
                } catch (e) {
                    setExampleData(null);
                }
            } else {
                setExampleData(null);
            }
        } else {
            setExampleData(null);
        }
    }

    if (loading || !scenes) {
        return (
            <Loader/>
        );
    }

    const onAdd = async () => {
        const scene = await SceneServices.createScene(project.id);
        history.push(`/dashboard/projects/${project.id}/scenes/${scene.id}`);
    };

    const onPlay = () => {
        if (scenes.length === 0) {
            return;
        }

        setCurrentSceneIndex(0);
        setPlaying(true);
    };

    const onPlayingSceneEnd = () => {
        // TODO proper logic to move to next available scene
        if (currentSceneIndex + 1 < scenes.length) {
            setCurrentSceneIndex(currentSceneIndex + 1);
        }
    };

    const getCurrentScene = () => {
        return scenes[currentSceneIndex];
    }

    const onSetExampleData = (data) => {
        setExampleData(data);
        localStorage[`${project.id}_example_data`] = JSON.stringify(data);
    };

    return (
        <div>
            <HeaderBar
                title={project.name}
                type="project"
                onPlay={() => onPlay()}
                onChangeExampleData={() => setShowExampleDataModal(true)}
            />
            <div style={theme.styles.mainContent}>
                <SectionTitle 
                    title={project.name}
                    onAdd={() => onAdd()}                
                    />
                <ScenesList
                    project={project}
                    exampleData={exampleData}
                    scenes={scenes}
                />
            </div>
            <ScenePreview
                playing={playing}
                scene={getCurrentScene()}
                project={project}
                objects={getCurrentScene() ? getCurrentScene().data.layout : null}
                data={getCurrentScene() ? getCurrentScene().data : null}
                exampleData={exampleData}
                onClose={() => setPlaying(false)}
                onSceneEnd={() => onPlayingSceneEnd()}
            />
            <ExampleDataModal
                visible={showExampleDataModal}
                exampleData={exampleData}
                onSetExampleData={(data) => onSetExampleData(data)}
                onHide={() => setShowExampleDataModal(false)}
            />
        </div>
    );
};