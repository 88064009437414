import AnimationServices from "./AnimationServices";

const FPS = 60;

export default {

    FPS: FPS,

    getMaxSceneDuration(data) {
        // Loop through all layout components and see which is on for the longest
        var currentMax = 0;

        data.ordering.forEach((objId) => {
            const obj = data.layout[objId];

            var duration = 0;

            if (obj.appearDelay) {
                duration += parseFloat(obj.appearDelay);
            }

            if (obj.appear && obj.appear.duration) {
                duration += parseFloat(obj.appear.duration);
            }

            if (obj.leave && obj.leave.duration) {
                duration += parseFloat(obj.leave.duration);
            }

            if (obj.leaveDelay) {
                duration += parseFloat(obj.leaveDelay);
            }

            if (duration > currentMax) {
                currentMax = duration;
            }
        });

        return currentMax;

    },

    createBlankKeyframes(sceneLength) {
        var keyframes = [];

        for (var i = 0; i < sceneLength * FPS; i++) {
            keyframes.push([]);
        } 

        return keyframes;
    },

    getInterpolatedCoordinates(obj, animation, progress) {
        if (!animation || !AnimationServices.ANIMATION_TYPES[animation.id] || !AnimationServices.ANIMATION_TYPES[animation.id].interpolate) {
            return {
                x: obj.position.x,
                y: obj.position.y
            }
        }

        return AnimationServices.ANIMATION_TYPES[animation.id].interpolate(obj.position, progress, animation.duration * FPS);
    },

    createTimeline(scene, data) {
        const sceneLength = this.getMaxSceneDuration(data);
        
        var keyframes = this.createBlankKeyframes(sceneLength);

        data.ordering.forEach((objId) => {
            const obj = data.layout[objId];

            var showAt = obj.appearDelay ? obj.appearDelay * FPS : 0;
            var appearDuration = obj.appear ? (obj.appear.duration * FPS) : 0;
            var hideAt = showAt + appearDuration + (obj.leaveDelay ? obj.leaveDelay * FPS : 0)
            var removeAt = (sceneLength * FPS) - 1;

            if (obj.leaveDelay) {
                // TODO add in animation durations here
                removeAt = hideAt + (obj.leaveDelay * FPS);
            }

            for (var i = Math.floor(showAt); i < Math.floor(removeAt) && i < sceneLength * FPS; i++) {
                if (i >= showAt && i < hideAt) {
                    // Currently in show animation
                    const coordinates = this.getInterpolatedCoordinates(obj, obj.appear, (i - showAt));
                    keyframes[i].push({
                        id: obj.id,
                        x: coordinates.x,
                        y: coordinates.y,
                        opacity: coordinates.opacity
                    });
                } else if (i >= hideAt) {
                    const coordinates = this.getInterpolatedCoordinates(obj, obj.leave, (i - hideAt));
                    keyframes[i].push({
                        id: obj.id,
                        x: coordinates.x,
                        y: coordinates.y,
                        opacity: coordinates.opacity
                    });
                } else {
                    // keyframes[i].push({
                    //     id: obj.id,
                    //     x: obj.position.x,
                    //     y: obj.position.y
                    // });
                }
                
                
            }

        });

        return keyframes;
    }

};