import * as React from "react";

function SvgDeleteButton(props) {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 21 21"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20.046.954a2.5 2.5 0 010 3.536l-6.01 6.01 6.01 6.01a2.5 2.5 0 11-3.536 3.536l-6.01-6.011-6.01 6.01A2.5 2.5 0 11.954 16.51l6.011-6.01-6.01-6.01A2.5 2.5 0 114.49.954l6.01 6.01 6.01-6.01a2.5 2.5 0 013.536 0z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgDeleteButton;