import React, { useState } from "react";
import SectionTitle from "../components/SectionTitle";
import ProjectServices from "../services/ProjectServices";
import Themes from "../Themes";
import useColorScheme from "../hooks/useColorScheme";

import {useHistory} from "react-router-dom";
import TextBox from "../components/TextBox";
import Button from "../components/Button";
import HeaderBar from "../components/HeaderBar";

export default() => {

    const theme = Themes[useColorScheme()];
    const history = useHistory();

    const [projectName, setProjectName] = useState("");
    const [creating, setCreating] = useState(false);

    const localStyles = {
        fields: {
            display: "flex",
            flexDirection: "column",
            maxWidth: 400
        }
    };

    const createProject = async () => {
        if (projectName.length > 0) {
            setCreating(true);
            const project = await ProjectServices.createProject(projectName);
            history.push(`/dashboard/projects/${project.id}`);
        }
        
    };

    return (
        <div>
            <HeaderBar
                title="New Project"
            />
            <div style={theme.styles.mainContent}>
                <SectionTitle 
                    title="New Project"            
                    />

                <div style={localStyles.fields}>
                    <TextBox
                        label="Project Name"
                        placeholder="Project Name"
                        value={projectName}
                        onChange={(val) => setProjectName(val)}
                    />
                
                    <Button
                        label="Create"
                        loading={creating}
                        onClick={() => createProject()}
                    />
                
                </div>

            </div>

            


        </div>
    );
};