import userflixBox from "./images/userflix_box.svg";
import plusIcon from "./images/plusicon.svg";
import circleComponent from "./images/circle_component.svg";
import textComponent from "./images/text_component.svg";
import imageComponent from "./images/image_component.svg";
import rectComponent from "./images/rect_component.svg";
import addMenuBar from "./images/add_menu_bar.svg";

const PRIMARY_COLOR = "#ED5354";
const SECONDARY_COLOR = "#FCE20F";
// const ERROR_COLOR = "#F34F2E";

const FONTS = {
    light: "Work Sans",
    medium: "Work Sans",
    bold: "Work Sans",
    regular: "Work Sans" 
};

const FONT_WEIGHTS = {
    "bold": "700",
    "regular": "400",
    "medium": "500",
    "light": "300"
}

const getStyles = (colors) => {
    return {
        mainContainer: {
            display: "flex",
            flex: 1,
            backgroundColor: colors.background
        },
        mainContent: {
            position: "fixed",
            top: 80,
            left: 0,
            right: 0,
            bottom: 0,
            padding: 30,
            paddingTop: 10,
            overflow: "auto"
        },
        content: {
            display: "flex",
            flex: 1,
            paddingLeft: 20,
            paddingRight: 20,
        },
        description: {
            color: colors.lightText
        },
        centeredBox: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            margin: "auto",
            width: 500,
            maxWidth: "70%",
            maxHeight: "60%",
            padding: 40,
            borderRadius: 10,
            boxShadow: colors.shadow,
            backgroundColor: colors.lightBackground
        },
        label: {
            fontWeight: FONT_WEIGHTS.bold,
            fontSize: 14,
            opacity: 0.45,
            color: colors.lightText
        },
        smallHeading: {
            fontWeight: FONT_WEIGHTS.bold,
            fontSize: 14,
            color: colors.black
        },
        propertyInput: {
            borderWidth: 1,
            borderRadius: 2,
            borderStyle: "solid",
            borderColor: colors.lightBorder,
            color: colors.black,
            outline: "none",
            padding: 5
        },
        propertyTitle: {
            userSelect: "none",
            fontWeight: "700",
            fontSize: 12,
            marginBottom: 5
        },
        placeholder: {
            color: colors.lighterText,
            fontSize: 20,
            fontWeight: FONT_WEIGHTS.medium
        },
        card: {
            backgroundColor: colors.lightBackground,
            borderRadius: 6,
            padding: 20,
            marginRight: 30,
            marginBottom: 30,
            boxShadow: colors.shadow,
            userSelect: "none",
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        },
        cardTitle: {
            fontWeight: FONT_WEIGHTS.bold,
            color: colors.black,
            fontSize: 18
        }

    }
};

const COLORS = {
    light: {
        primary: PRIMARY_COLOR,
        secondary: SECONDARY_COLOR,
        background: "#F9F9F9",
        lightBackground: "#ffffff",
        white: "#ffffff",
        black: "#212121",
        alwaysBlack: "#000000",
        transparentBlack: "rgba(0,0,0,0.95)",
        border: "#CDCECC",
        lightBorder: "#F0F0F0",
        lightText: "#525451",
        lighterText: "#D0D0D0",
        shadow: "4px 0 22px 0 rgba(211,211,211,0.50)",
        bigShadow: "1px 1px 10px 2px rgba(211,211,211,0.30)",
        overlay: "rgba(0,0,0,0.5)"
    },
    dark: {
        primary: PRIMARY_COLOR,
        secondary: SECONDARY_COLOR,
        background: "#3b3638",
        lightBackground: "#32272c",
        white: "#3b3638",
        black: "#ffffff",
        alwaysBlack: "#000000",
        transparentBlack: "rgba(0,0,0,0.95)",
        bigShadow: "1px 1px 10px 2px rgba(211,211,211,0.30)",
        border: "#605b5e",
        lightBorder: "#605b5e",
        lightText: "#525451",
        lighterText: "#ffffff",
        shadow: "4px 0 22px 0 rgba(1,0,0,0.16)",
        overlay: "rgba(0,0,0,0.2)"
    }
}

export default {
    light: {
        colors: COLORS.light,
        fonts: FONTS,
        weights: FONT_WEIGHTS,
        images: {
            userflixBox: userflixBox,
            plusIcon: plusIcon,
            circleComponent: circleComponent,
            textComponent: textComponent,
            imageComponent: imageComponent,
            rectComponent: rectComponent,
            addMenuBar: addMenuBar
        },
        styles: getStyles(COLORS.light)
    },
    dark: {
        colors: COLORS.dark,
        fonts: FONTS,
        weights: FONT_WEIGHTS,
        images: {
            userflixBox: userflixBox,
            plusIcon: plusIcon,
            circleComponent: circleComponent,
            textComponent: textComponent,
            imageComponent: imageComponent,
            rectComponent: rectComponent,
            addMenuBar: addMenuBar
        },
        styles: getStyles(COLORS.dark)
    }
};