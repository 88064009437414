import './Placeholder.css';
import Logo from "./components/Logo";

const COLORS = [
  {background: "#ED5354", color: "#FCE20F"},
  {background: "#FDEE77", color: "#F96065"},
  {background: "#080952", color: "#EA2188"},
  {background: "#3F8DFE", color: "#FFFFFF"},
  {background: "#2E2E2A", color: "#F8D342"}
];

const COLOR = COLORS[Math.floor(Math.random()* COLORS.length)];

function App() {
  return (
    <div className="App" style={{backgroundColor: COLOR.background}}>
      <header className="App-header">
        <Logo
          height="50%"
          width="50%"
          color={COLOR.color}
        />
      </header>
      
    </div>
  );
}

export default App;
