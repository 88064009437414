import * as React from "react";

function SvgAddCircleButton(props) {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 35 35"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx={78.5}
        cy={195.5}
        r={17.5}
        transform="translate(-61 -178)"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgAddCircleButton;
