import React from "react";
import Themes from "../Themes";
import useColorScheme from "../hooks/useColorScheme";

export default({onClick}) => {
    const theme = Themes[useColorScheme()];

    const localStyles = {
        container: {
            width: 22,
            height: 22,
            borderRadius: 11,
            backgroundColor: theme.colors.primary,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginLeft: 20,
            cursor: "pointer"
        },
        icon: {
            width: 12,
            height: 12
        }
    };

    return (
        <div style={localStyles.container} onClick={onClick}>
            <img alt="Add" src={theme.images.plusIcon} style={localStyles.icon}/>
        </div>
    );

};