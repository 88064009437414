import React from "react";

const SIZE = 8;

export default({visible, top, left, bottom, right, cursor, onBeginResize, onEndResize}) => {
    if (!visible) {
        return null;
    }

    const localStyles = {
        container: {
            position: "absolute",
            top: top !== null && top !== undefined ? top - (SIZE) : null,
            left: left !== null && left !== undefined ? left - (SIZE) : null,
            right: right !== null && right !== undefined ? right - (SIZE) : null,
            bottom: bottom !== null && bottom !== undefined ? bottom - (SIZE) : null,
            width: SIZE,
            height: SIZE,
            borderRadius: 1,
            backgroundColor: "rgba(255,255,255,1)",
            borderStyle: "solid",
            borderWidth: 1,
            borderColor: "rgba(0,0,0,1)",
            cursor: cursor
        }
    };

    const onMouseDown = (evt) => {
        evt.stopPropagation();
        onBeginResize(evt);
    };

    return (
        <div style={localStyles.container} onMouseDown={(evt) => onMouseDown(evt)}>

        </div>
    );

};