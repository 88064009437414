import React from "react";
import useColorScheme from "../hooks/useColorScheme";
import Themes from "../Themes";
import Card from "./Card";

export default({project, onClick}) => {
    const theme = Themes[useColorScheme()];

    const localStyles = {
        container: {
            width: 150,
            height: 130
        }
    };

    return (
        <Card onClick={onClick}>
            <div style={localStyles.container}>
                <div style={theme.styles.cardTitle}>{project.name}</div>
            </div>
        </Card>
    );
};