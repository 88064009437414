import React from "react";
import AddButton from "./AddButton";

export default({title, onAdd}) => {
    
    const localStyles = {
        container: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            userSelect: "none"
        }
    };

    const renderAdd = () => {
        if (!onAdd) {
            return null;
        }

        return (
            <AddButton
                onClick={onAdd}
            />
        );
    };

    return (
        <div style={localStyles.container}>
            <h1>{title}</h1>
            {renderAdd()}
        </div>
    );
};