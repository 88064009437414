import React from "react";
import useColorScheme from "../hooks/useColorScheme";
import Themes from "../Themes";
import Spinner from "./Spinner";

export default({label, onClick, width, loading, disabled}) => {
    const theme = Themes[useColorScheme()];

    const localStyles = {
        container: {
            width: width || "100%",
            height: 50,
            backgroundColor: theme.colors.black,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: 4,
            userSelect: "none",
            cursor: "pointer",
            opacity: disabled ? 0.5 : 1
        },
        label: {
            color: theme.colors.white,
            fontWeight: theme.weights.bold
        }
    };

    const renderContent = () => {
        if (loading) {
            return (
                <Spinner
                    spinning={true}
                    size="small"
                />
            );
        }

        return (
            <div style={localStyles.label}>{label}</div>
        );
    };

    const click = () => {
        if (!disabled) {
            onClick();
        }
    }

    return (
        <div style={localStyles.container} onClick={() => click()}>
            {renderContent()}
        </div>
    );
};