import HttpServices from "./HttpServices";

export default {

    async listScenesForProject(projectId) {
        return await HttpServices.get(`/project/${projectId}/scene`);
    },

    async loadScene(projectId, sceneId) {
        return await HttpServices.get(`/project/${projectId}/scene/${sceneId}`);
    },

    async updateSceneData(projectId, sceneId, data) {
        return await HttpServices.patch(`/project/${projectId}/scene/${sceneId}/data`, {
            data: data
        });
    },

    async createScene(projectId) {
        return await HttpServices.post(`/project/${projectId}/scene`, {
            
        });
    },

};