import HttpServices from "./HttpServices";

export default {

    isLoggedIn() {
        if (!localStorage.token) {
            return false;
        }

        return true;
    },

    async login(email, password) {
        return await HttpServices.login(email, password);
    }
}