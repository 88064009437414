import React, { useEffect, useState } from "react";

import {
    Switch,
    Route
  } from "react-router-dom";

import UserServices from "../services/UserServices";
import Projects from "./Projects";
import ViewProject from "./ViewProject";
import ViewScene from "./ViewScene";
import NewProject from "./NewProject";

export default() => {

    const [checkingLogin, setCheckingLogin] = useState(true);
    const [loggedIn, setLoggedIn] = useState(null); // eslint-disable-line

    const localStyles = {
        container: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0
        },
        mainSection: {
            position: "fixed",
            left: 0,
            top: 0,
            bottom: 0,
            right: 0,
            paddingLeft: 30,
            paddingRight: 30,
            paddingTop: 10,
            overflow: "auto"
        }
    };

    useEffect(() => {
        if (!UserServices.isLoggedIn()) {
            window.location.href = "/login";
        } else {
            setCheckingLogin(false);
            setLoggedIn(true);
        }
    }, []);

    if (checkingLogin) {
        return null;
    }

    return (
        <div style={localStyles.container}>
            <div style={localStyles.mainSection}>
                <Switch>
                    <Route path="/dashboard/projects/:projectId/scenes/:sceneId" exact>
                        <ViewScene/>
                    </Route>
                    <Route path="/dashboard/projects/new" exact>
                        <NewProject/>
                    </Route>
                    <Route path="/dashboard/projects/:id" exact>
                        <ViewProject/>
                    </Route>
                    <Route path="/dashboard" exact>
                        <Projects/>
                    </Route>
                    
                </Switch>
            </div>
        </div>
    )
};