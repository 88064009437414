import React from "react";
import AnimationServices from "../services/AnimationServices";
import AnimationBox from "./AnimationBox";
import DropDownProperty from "./properties/DropDownProperty";

export default({availableAnimations, property, selectedObject, onSelect}) => {
    const localStyles = {
        container: {
            marginTop: 10,
            marginBottom: 10,
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
        },
        content: {
            padding: 10,
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap"
        }
    };

    const isSelected = (anim) => {
        if (selectedObject && selectedObject[property] && selectedObject[property].id === anim.id) {
            return true;
        }

        return false;
    }

    const getValue = () => {
        if (selectedObject[property] && selectedObject[property].id && AnimationServices.ANIMATION_TYPES[selectedObject[property].id]) {
            return AnimationServices.ANIMATION_TYPES[selectedObject[property].id].title;
        }

        return "None";
    };

    const onSelectAnimation = (anim) => {
        if (!anim) {
            onSelect(anim);
        }

        onSelect({
            id: anim.id,
            duration: anim.preview.transition.duration
        });
        
    };

    const renderDropDownContent = () => {
        return (
            <div style={localStyles.content}>
                {availableAnimations.map((anim) => {
                    return (
                        <AnimationBox
                            key={anim.id}
                            selected={isSelected(anim)}
                            onSelect={() => onSelectAnimation(anim)}
                            animation={anim}
                        />
                    );
                })}
                
            </div>
        );
    };

    return (
        <DropDownProperty
            value={getValue()}
            renderContent={() => renderDropDownContent()}
        />
    );
};