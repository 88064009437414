import React, { useEffect, useState } from "react";
import Loader from "../components/Loader";
import useColorScheme from "../hooks/useColorScheme";
import Themes from "../Themes";
import {useParams} from "react-router-dom";
import SceneServices from "../services/SceneServices";
import SceneEditor from "../components/SceneEditor";
import HeaderBar from "../components/HeaderBar";
import ScenePropertyInspector from "../components/ScenePropertyInspector";
import { v4 as uuidv4 } from 'uuid';
import AnimationPropertyInspector from "../components/AnimationPropertyInspector";
import ScenePreview from "../components/ScenePreview";
import ProjectServices from "../services/ProjectServices";

import {
    useHistory
  } from "react-router-dom";
import ExampleDataModal from "../modals/ExampleDataModal";

export default() => {

    const theme = Themes[useColorScheme()];
    const history = useHistory();

    const [project, setProject] = useState(null);
    const [scene, setScene] = useState(null);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);
    const [selectedObject, setSelectedObject] = useState(null);
    const [addingObject, setAddingObject] = useState(null);
    const [playing, setPlaying] = useState(false);
    const [showExampleDataModal, setShowExampleDataModal] = useState(false);
    const [exampleData, setExampleData] = useState(null);

    const {projectId, sceneId} = useParams();

    const localStyles = {
        icons: {
            flexDirection: "row",
            alignItems: "center",
            display: "flex",
            marginBottom: 20
        },
        zoomer: {
            position: "absolute",
            top: 0,
            left: 200,
            bottom: 0,
            right: 0,
            paddingTop: 50,
            paddingLeft: 50
        }
    };

    useEffect(() => {
        reload();
    }, []); //eslint-disable-line

    useEffect(() => {
        if (project) {
            if (localStorage[`${project.id}_example_data`]) {
                try {
                    setExampleData(JSON.parse(localStorage[`${project.id}_example_data`]));
                } catch (e) {
                    setExampleData(null);
                }
            } else {
                setExampleData(null);
            }
        } else {
            setExampleData(null);
        }
    }, [project]);

    const reload = async() => {
        const loadedProject = await ProjectServices.loadProject(projectId);
        setProject(loadedProject);

        const loadedScene = await SceneServices.loadScene(projectId, sceneId);
        setScene(loadedScene);
        setData(loadedScene.data);
        setLoading(false);
    };

    const onUpdateObjects = async (objs) => {
        var updatedData = Object.assign({}, data);
        updatedData.layout = objs;

        setData(updatedData);
        await SceneServices.updateSceneData(projectId, scene.id, updatedData);
    };

    const onAddNewObject = (obj) => {
        setSelectedObject(null);
        setAddingObject(obj);
    };

    const onChangeAddingObject = (obj) => {
        setAddingObject(obj);
    };

    const onAddedObject = async () => {
        // TODO Add Object
        var updatedData = Object.assign({}, data);

        const id = uuidv4();
        updatedData.layout[id] = addingObject;
        updatedData.layout[id].id = id;
        updatedData.ordering.push(id);

        setData(updatedData);
        await SceneServices.updateSceneData(projectId, scene.id, updatedData);
        
        setAddingObject(null);
    }

    const onChangeObject = async (obj) => {
        var updatedData = Object.assign({}, data);

        updatedData.layout[obj.id] = obj;

        setData(updatedData);
        await SceneServices.updateSceneData(projectId, scene.id, updatedData);
    }

    const onSelectObject = (obj) => {
        setSelectedObject(obj);
    }

    const onDeleteSelected = async () => {
        var updatedData = Object.assign({}, data);

        delete updatedData.layout[selectedObject.id];

        // Remove the id from the ordering list
        for (var i = 0; i < updatedData.ordering.length; i++) {
            if (updatedData.ordering[i] === selectedObject.id) {
                updatedData.ordering.splice(i, 1);
            }
        }

        setData(updatedData);
        await SceneServices.updateSceneData(projectId, scene.id, updatedData);
        
        setSelectedObject(null);

    }

    const onDuplicateSelected = async () => {
        var updatedData = Object.assign({}, data);

        // Generate new id and object
        const id = uuidv4();
        updatedData.layout[id] = Object.assign({}, selectedObject);
        updatedData.layout[id].id = id;
        updatedData.ordering.push(id);

        setData(updatedData);
        await SceneServices.updateSceneData(projectId, scene.id, updatedData);
        
        setSelectedObject(updatedData.layout[id]);

    }

    if (loading) {
        return (
            <Loader/>
        );
    }

    const getSelectedObject = () => {
        if (!selectedObject) {
            return null;
        }

        return data.layout[selectedObject.id];

    }

    const onPlay = () => {
        setPlaying(true);
    };

    const onMoveForward = async () => {
        await onMoveInDirection(1);
    };

    const onMoveBackward = async () => {
        await onMoveInDirection(-1);
    };

    const onSetExampleData = (data) => {
        setExampleData(data);
        localStorage[`${project.id}_example_data`] = JSON.stringify(data);
    };

    const onMoveInDirection = async(direction) => {
        var updatedData = Object.assign({}, data);

        var updatedOrdering = [].concat(data.ordering);

        // Find this id
        var idIndex = null;

        for (var i = 0; i < updatedOrdering.length; i++) {
            if (updatedOrdering[i] === getSelectedObject().id) {
                idIndex = i;
                break;
            }
        }

        if (idIndex !== null) {
            if ((direction > 0 && idIndex < updatedOrdering.length - 1) || (direction < 0 && idIndex > 0)) {
                // Swap around
                var tempId = updatedOrdering[idIndex + direction];
                updatedOrdering[idIndex + direction] = getSelectedObject().id;
                updatedOrdering[idIndex] = tempId;
                updatedData.ordering = updatedOrdering;
                setData(updatedData);
                await SceneServices.updateSceneData(projectId, scene.id, updatedData);
            }
        }
    };

    const onGoToProject = () => {
        history.push(`/dashboard/projects/${project.id}`);
    };

    return (
        <div>
            <HeaderBar
                title={project.name || "Untitled"}
                onTitleClick={() => onGoToProject()}
                type="scene"
                onAdd={(obj) => onAddNewObject(obj)}
                onPlay={() => onPlay()}
                onMoveBackward={() => onMoveBackward()}
                onMoveForward={() => onMoveForward()}
                selectedObject={getSelectedObject()}
                onDelete={() => onDeleteSelected()}
                onDuplicate={() => onDuplicateSelected()}
                onChangeExampleData={() => setShowExampleDataModal(true)}
            />
            <div style={theme.styles.mainContent}>
                <ScenePropertyInspector
                    selectedObject={getSelectedObject()}
                    onChangeObject={(updatedObj) => onChangeObject(updatedObj)}
                />
                <div style={localStyles.zoomer}>
                    <SceneEditor
                        scene={scene}
                        project={project}
                        exampleData={exampleData}
                        defaultZoom={1}
                        addingObject={addingObject}
                        onChangeAddingObject={(obj) => onChangeAddingObject(obj)}
                        onAddedObject={(obj) => onAddedObject(obj)}
                        objects={data.layout}
                        ordering={data.ordering}
                        selectedObject={getSelectedObject()}
                        onSelectObject={(obj) => onSelectObject(obj)}
                        onUpdateObjects={(objs) => onUpdateObjects(objs)}
                    />  
                </div>
                <AnimationPropertyInspector
                    selectedObject={getSelectedObject()}
                    onChangeObject={(updatedObj) => onChangeObject(updatedObj)}
                />
                
            </div>
            <ScenePreview
                playing={playing}
                scene={scene}
                project={project}
                objects={data.layout}
                data={data}
                exampleData={exampleData}
                onClose={() => setPlaying(false)}
            />
            <ExampleDataModal
                visible={showExampleDataModal}
                exampleData={exampleData}
                onSetExampleData={(data) => onSetExampleData(data)}
                onHide={() => setShowExampleDataModal(false)}
            />
        </div>
    );
};