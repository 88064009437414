import React, {useState} from "react";
import ComponentServices from "../services/ComponentServices";
import DraggableResizable from "./DraggableResizable";
import Circle from "./objects/Circle";
import Image from "./objects/Image";
import Text from "./objects/Text";
import Rectangle from "./objects/Rectangle";
import SceneEditorAddingLayer from "./SceneEditorAddingLayer";

const DEFAULT_SIZE = {
    width: 426,
    height: 240,
    zoom: 1
};

export default({
    project, 
    scene, 
    selectedObject, 
    onSelectObject, 
    onDeletePress,
    readOnly, 
    onClick,
    exampleData,
    ordering,
    defaultZoom, 
    fitWidth, 
    objects, 
    onUpdateObjects, 
    addingObject, 
    onAddedObject,
    onDeletedObject,
    onChangeAddingObject}) => {

    const [startX, setStartX] = useState(null);
    const [startY, setStartY] = useState(null);
    const [resizing, setResizing] = useState(null);
    const [movingTransform, setMovingTransform] = useState(null);
    const [resizingTransform, setResizingTransform] = useState(null);

    const getScreenSize = () => {
        if (!project) {
            return DEFAULT_SIZE;
        }

        if (project.width && project.height) {
            return {
                width: project.width,
                height: project.height,
                zoom: 1
            }
        }

        return DEFAULT_SIZE;
    };

    var zoomToUse = defaultZoom || 1;

    if (fitWidth) {
        const ratio = fitWidth / getScreenSize().width;
        zoomToUse = ratio;
    }
    
    const [zoom, setZoom] = useState(zoomToUse); // eslint-disable-line
    const [dragging, setDragging] = useState(null);

    const sizing = getScreenSize();

    const localStyles = {
        container: {
            position: "relative",
            width: sizing.width,
            height: sizing.height,
            transform: `scale(${zoom})`,
            transformOrigin: "top left",
            backgroundColor: project.background_color || "#000000",
            overflow: "hidden",
            cursor: addingObject ? "crosshair" : undefined
        },
        addObjectUnderlay: {
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0
        },
        adding: {
            position: "absolute",
            //top: addingObject && addingObject.position ? addingObject.position.y : 0,
            left: addingObject && addingObject.position ? addingObject.position.x : undefined,
            top: addingObject && addingObject.position ? addingObject.position.y : undefined
        }
    };

    const handleMouseMoveResize = (evt) => {
        if (!selectedObject) {
            return;
        }

        evt.preventDefault();
        // Need to do something with the resizing
        const obj = objects[selectedObject.id];

        var transform = {};

        if (resizing.left) {
            // The top coordinate is moveable
            const changeInX = (evt.clientX - resizing.startX) / zoom;
            
            var newWidth = 0;

            if (changeInX < 0) {
                newWidth = obj.style.width + Math.abs(changeInX);
            } else if (changeInX > 0) {
                newWidth = obj.style.width - changeInX;
            }

            transform.x = obj.position.x + changeInX;
            transform.width = newWidth;
        }

        if (resizing.right) {
            // The top coordinate is moveable
            const changeInX = (evt.clientX - resizing.startX) / zoom;
            
            var newWidth = 0;

            if (changeInX < 0) {
                newWidth = obj.style.width - Math.abs(changeInX);
            } else if (changeInX > 0) {
                newWidth = obj.style.width + Math.abs(changeInX);
            }

            transform.x = obj.position.x;
            transform.width = newWidth;
        }

        if (resizing.top) {
            // The top coordinate is moveable
            const changeInY = (evt.clientY - resizing.startY) / zoom;
            
            var newHeight = 0;

            if (changeInY < 0) {
                newHeight = obj.style.height + Math.abs(changeInY);
            } else if (changeInY > 0) {
                newHeight = obj.style.height - changeInY;
            }

            transform.y = obj.position.y + changeInY;
            transform.height = newHeight;
        }

        if (resizing.bottom) {
            // The top coordinate is moveable
            const changeInY = (evt.clientY - resizing.startY) / zoom;
            
            var newHeight = 0;

            if (changeInY < 0) {
                newHeight = obj.style.height - Math.abs(changeInY);
            } else if (changeInY > 0) {
                newHeight = obj.style.height + Math.abs(changeInY);
            }

            transform.y = obj.position.y;
            transform.height = newHeight;
        }


        setResizingTransform(transform);
    }

    const onMouseMove = (evt) => {
        if (readOnly) {
            return;
        }

        if (resizing) {
            handleMouseMoveResize(evt);
            return;
        }

        if (dragging) {
            setResizing(null);
            setMovingTransform({
                x: (evt.clientX - startX) / zoom,
                y: (evt.clientY - startY)  / zoom
            });
        }
    };

    const saveResizingTransform = () => {
        if (!resizingTransform) {
            return;
        }

        const obj = objects[selectedObject.id];

        // First deal with 
        var updatedPosition = {
            x: obj.position.x,
            y: obj.position.y
        };

        if (resizingTransform.x) {
            updatedPosition.x = resizingTransform.x;
        }

        if (resizingTransform.y) {
            updatedPosition.y = resizingTransform.y;
        }

        setObjectPosition(selectedObject.id, updatedPosition);

        // Now do the height/width
        var updatedStyle = Object.assign({}, obj.style);
        
        if (resizingTransform.width) {
            updatedStyle.width = resizingTransform.width;
        }

        if (resizingTransform.height) {
            updatedStyle.height = resizingTransform.height;
        }

        setObjectStyle(selectedObject.id, updatedStyle);

        setResizing(false);
    };

    const onMouseUp = (evt) => {
        if (readOnly) {
            return;
        }

        setResizingTransform(null);

        if (resizing) {
            saveResizingTransform();
            return;
        }

        if (addingObject) {
            onAddedObject();
            return;
        }

        if (dragging) {
            // Update pos of object
            setObjectPosition(dragging.id, {
                x: objects[dragging.id].position.x + ((evt.clientX - startX) / zoom),
                y: objects[dragging.id].position.y + ((evt.clientY - startY) / zoom)
            });

            setMovingTransform(null);
            setDragging(null);
        }
    }

    const onDragStart = (id, evt, obj) => {
        if (readOnly) {
            return;
        }

        setStartX(evt.clientX);
        setStartY(evt.clientY);
        setDragging({
            id: id
        });
        onSelectObject(obj);
        evt.stopPropagation();
    };

    const setObjectPosition = (id, position) => {
        var updatedObjects = objects;
        updatedObjects[id].position = position;
        onUpdateObjects(updatedObjects);
    };

    const setObjectStyle = (id, style) => {
        var updatedObjects = objects;
        updatedObjects[id].style = style;
        onUpdateObjects(updatedObjects);
    };

    const selectObject = (obj) => {
        if (readOnly) {
            return;
        }
        
        onSelectObject(obj);
    }

    const renderObject = (object) => {
        const transform = isSelected(object) ? resizingTransform : null;

        if (object.type === ComponentServices.TYPES.circle.id) {
            return (
                <Circle
                    object={object}
                    exampleData={exampleData}
                    resizingTransform={transform}
                />
            );
        } else if (object.type === ComponentServices.TYPES.image.id) {
            return (
                <Image
                    object={object}
                    resizingTransform={transform}
                    exampleData={exampleData}
                />
            );
        } else if (object.type === ComponentServices.TYPES.text.id) {
            return (
                <Text 
                    object={object}
                    resizingTransform={transform}
                    exampleData={exampleData}
                    />
            )
        } else if (object.type === ComponentServices.TYPES.rectangle.id) {
            return (
                <Rectangle 
                    object={object}
                    resizingTransform={transform}
                    />
            );
        }


        return null;
    };

    const onBeginResize = (pos) => {
        setResizingTransform(null);
        setResizing(pos);
    };

    const clearSelectedObject = () => {
        if (readOnly) {
            if (onClick) {
                onClick();
            }
        } else if (onSelectObject) {
            onSelectObject(null)
        }
    };

    const renderAddingObject = () => {
        if (!addingObject) {
            return null;
        }

        return (
            <div style={localStyles.addObjectUnderlay}>
                <div style={localStyles.adding}>
                    {renderObject(addingObject)}
                </div>
                <SceneEditorAddingLayer
                    addingObject={addingObject}
                    zoom={zoom}
                    onChangeAddingObject={onChangeAddingObject}
                    onAddedObject={onAddedObject}
                />
            </div>
            
        );
    };

    const isSelected = (obj) => {
        return selectedObject && selectedObject.id === obj.id;
    };

    const getOrderedObjects = () => {
        if (!ordering) {
            return [];
        }
        return ordering;
    };

    return (
        <div style={localStyles.container} onMouseMove={(evt) => onMouseMove(evt)} onMouseUp={(evt) => onMouseUp(evt)} onClick={() => clearSelectedObject()}>
            {getOrderedObjects().map((id) => {
                const obj = objects[id];

                return (
                    <DraggableResizable 
                        key={obj.id}
                        readOnly={readOnly}
                        object={obj}
                        selected={isSelected(obj)}
                        position={obj.position}
                        screenSize={getScreenSize()}
                        resizingTransform={isSelected(obj) ? resizingTransform : null}
                        transform={dragging && dragging.id === obj.id ? movingTransform : null}
                        onSelect={() => selectObject(obj)}
                        onBeginResize={(pos) => onBeginResize(pos)}
                        onDragStart={(evt) => onDragStart(id, evt, obj)}
                        >
                            {renderObject(obj)}
                    </DraggableResizable>
                );
            })}
            {renderAddingObject()}
        </div>
    );
};