import React from "react";
import HttpServices from "../../services/HttpServices";
import SubstitutionServices from "../../services/SubstitutionServices";

export default({object, resizingTransform, exampleData}) => {

    const getBorderRadius = () => {
        if (!object.style.borderRadius) {
            return 0;
        }

        if (resizingTransform && resizingTransform.width && resizingTransform.height) {
            if (resizingTransform.height > resizingTransform.width) {
                return resizingTransform.height / 2;
            } else {
                return resizingTransform.width / 2;
            }
        }

        if (object.style.height > object.style.width) {
            return object.style.height / 2;
        } else {
            return object.style.width / 2;
        }
    };

    var width = resizingTransform && resizingTransform.width ? resizingTransform.width : object.style.width;
    var height = resizingTransform && resizingTransform.height ? resizingTransform.height : object.style.height;

    if (object.style.borderWidth) {
        width = width - (object.style.borderWidth * 2);
        height = height - (object.style.borderWidth * 2);
    }

    const localStyles = {
        container: {
            cursor: "pointer",
            position: "absolute",
            height: height,
            width: width,
            borderRadius: getBorderRadius(),
            backgroundColor: object.image ? null : object.style.backgroundColor,
            borderColor: object.style.borderColor,
            borderWidth: object.style.borderWidth,
            borderStyle: object.style.borderWidth ? "solid" : null
        }
    };        

    const renderImage = () => {
        if (!HttpServices.isUrlValid(SubstitutionServices.makeSubstitutions(object.image, exampleData))) {
            return null;
        }

        return (
            <img 
                draggable={false}
                alt="Object"
                src={SubstitutionServices.makeSubstitutions(object.image, exampleData)}
                style={localStyles.container}/>
        );
    };

    return (
        <div style={localStyles.container}>
            {renderImage()}
        </div>
    );
};