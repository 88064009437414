import React from "react";
import useColorScheme from "../hooks/useColorScheme";
import AnimationServices from "../services/AnimationServices";
import ComponentServices from "../services/ComponentServices";
import Themes from "../Themes";
import AnimationSelector from "./AnimationSelector";
import Property from "./Property";

export default({selectedObject, onChangeObject}) => {

    const theme = Themes[useColorScheme()];

    const localStyles = {
        container: {
            userSelect: "none",
            position: "absolute",
            top: 1,
            bottom: 0,
            right: 0,
            width: 220,
            backgroundColor: theme.colors.white,
            borderLeftColor: theme.colors.lightBorder,
            borderLeftWidth: 1,
            borderLeftStyle: "solid"
        },
        section: {
            marginTop: 10
        },
        innerContainer: {
            padding: 30
        },
        propertyTitle: {
            fontSize: 14
        },
        properties: {
            paddingTop: 10
        },
        propertyHolder: {
            paddingTop: 10
        }
    };

    const onSelectAnimation = (property, animation) => {
        var updatedObj = Object.assign({}, selectedObject);
        updatedObj[property] = animation;
        onChangeObject(updatedObj);
    }

    const renderContent = () => {
        if (!selectedObject) {
            return null;
        }

        return (
            <div style={localStyles.innerContainer}>
                <div style={theme.styles.smallHeading}>Animation</div>
                <div style={localStyles.propertyHolder}>
                    <Property
                        property={ComponentServices.PROPERTIES.appearDelay}
                        object={selectedObject}
                        onChangeObject={onChangeObject}
                    />
                </div>
                
                <div style={localStyles.section}>
                    <div style={theme.styles.propertyTitle}>Appear</div>
                    <AnimationSelector
                        availableAnimations={AnimationServices.APPEAR_ANIMATIONS}
                        selectedObject={selectedObject}
                        property="appear"
                        onChangeObject={onChangeObject}
                        onSelect={(animation) => onSelectAnimation("appear", animation)}
                    />
                </div>
                <div style={localStyles.propertyHolder}>
                    <Property
                        property={ComponentServices.PROPERTIES.leaveDelay}
                        object={selectedObject}
                        onChangeObject={onChangeObject}
                    />
                </div>
                <div style={localStyles.section}>
                    <div style={theme.styles.propertyTitle}>Leave</div>
                    <AnimationSelector
                        availableAnimations={AnimationServices.LEAVE_ANIMATIONS}
                        selectedObject={selectedObject}
                        property="leave"
                        onChangeObject={onChangeObject}
                        onSelect={(animation) => onSelectAnimation("leave", animation)}
                    />
                </div>
            </div>
                
                
        );
    };

    return (
        <div style={localStyles.container}>
            {renderContent()}
        </div>
    );
};