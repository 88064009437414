import React from "react";
import useColorScheme from "../../hooks/useColorScheme";
import Themes from "../../Themes";

export default({object, onChange, property, value}) => {
    const theme = Themes[useColorScheme()];

    const change = (val) => {
        onChange(val);
    };

    return (
        <input 
            type="text"
            style={theme.styles.propertyInput}
            value={value}
            placeholder={property.placeholder || "Enter or select URL"}
            onChange={(evt) => change(evt.target.value)}
            />
    );
};