import React from "react";
import ComponentServices from "../services/ComponentServices";
import Rectangle from "./objects/Rectangle";
import Circle from "./objects/Circle";
import Image from "./objects/Image";
import Text from "./objects/Text";

export default({project, frameNumber, timeline, objects, exampleData}) => {

    if (!timeline || timeline.length < frameNumber) {
        return null;
    }

    const localStyles = {
        container: {
            position: "absolute",
            backgroundColor: project.background_color || "#000000",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0
        }
    };

    const renderObject = (object, resizingTransform) => {
        if (!object) {
            // Deals with switching between scenes in preview
            return null;
        }

        if (object.type === ComponentServices.TYPES.circle.id) {
            return (
                <Circle
                    exampleData={exampleData}
                    object={object}
                    resizingTransform={resizingTransform}
                />
            );
        } else if (object.type === ComponentServices.TYPES.image.id) {
            return (
                <Image
                    exampleData={exampleData}
                    object={object}
                    resizingTransform={resizingTransform}
                />
            );
        } else if (object.type === ComponentServices.TYPES.text.id) {
            return (
                <Text 
                    object={object}
                    exampleData={exampleData}
                    />
            )
        } else if (object.type === ComponentServices.TYPES.rectangle.id) {
            return (
                <Rectangle 
                    object={object}
                    />
            );
        }

        return null;
    };

    const keyframe = timeline[frameNumber];

    if (!keyframe || keyframe.length === 0) {
        return null;
    }

    return (
        <div style={localStyles.container}>
            {keyframe.map((obj) => {
                const object = objects[obj.id];

                var positioning = {
                    position: "absolute",
                    left: obj.x,
                    top: obj.y,
                    opacity: obj.opacity !== undefined ? obj.opacity : 1
                };

                return (
                    <div key={obj.id} style={positioning}>
                        {renderObject(object)}
                    </div>
                );
            })}
        </div>
    );
};