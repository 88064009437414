import React, { useState } from "react";
import useColorScheme from "../hooks/useColorScheme";
import Themes from "../Themes";
import { motion } from "framer-motion";

export default({selected, onSelect, animation}) => {
    const theme = Themes[useColorScheme()];
    const [preview, setPreview] = useState(false);

    const localStyles = {
        container: {
            width: 40,
            height: 40,
            marginRight: 10,
            marginBottom: 10,
            borderRadius: 2,
            boxShadow: theme.colors.shadow,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
            overflow: "hidden",
            cursor: "pointer",
            borderWidth: 1,
            borderStyle: "solid",
            borderColor: selected ? theme.colors.primary : "transparent"
        },
        circle: {
            width: 10,
            height: 10,
            borderRadius: 5,
            opacity: 1,
            backgroundColor: theme.colors.primary
        },
        overlay: {
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0
        }
    };

    const getAnimation = () => {
        if (!preview) {
            return null;
        }

        return animation.preview.animation;
    };

    const getTransition = () => {
        if (!preview) {
            return null;
        }

        return animation.preview.transition;
    };

    const getInitial = () => {
        if (!preview) {
            return null;
        }

        return animation.preview.initial;
    };

    const getExit = () => {
        if (!preview) {
            return null;
        }

        return animation.preview.exit;
    };

    const getStartStyle = () => {
        return localStyles.circle;
    };

    return (
        <div style={localStyles.container} onClick={onSelect}>
            <motion.div 
                style={getStartStyle()}
                animate={getAnimation()}
                exit={getExit()}
                initial={getInitial()}
                transition={getTransition()}
                />
            <div style={localStyles.overlay} onMouseOver={() => setPreview(true)} onMouseOut={() => setPreview(false)}>

            </div>
        </div>
    )
};