import HttpServices from "./HttpServices";

export default {

    async listProjects() {
        return await HttpServices.get(`/project`);
    },

    async loadProject(id) {
        return await HttpServices.get(`/project/${id}`);
    },

    async createProject(name) {
        return await HttpServices.post(`/project`, {
            name: name
        });
    }

};