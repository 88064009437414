import * as React from "react";

function SvgMoveToFrontButton(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 23 29"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g transform="matrix(1 0 0 -1 0 29)" fill="currentColor" fillRule="evenodd">
        <rect width={23} height={6} rx={3} />
        <rect opacity={0.418} y={9} width={23} height={6} rx={3} />
        <path d="M12 1v17h4l-4.5 9L7 18h4V1h1z" fillRule="nonzero" />
      </g>
    </svg>
  );
}

export default SvgMoveToFrontButton;

