import React from "react";
import useColorScheme from "../../hooks/useColorScheme";
import Themes from "../../Themes";
import SvgSpeechPointer from "../svgs/SpeechPointer";

const OFFSET = 17;

export default({visible, onHide, renderContent}) => {
    if (!visible) {
        return null;
    }

    const theme = Themes[useColorScheme()];

    const targetDomNode = visible.target.getBoundingClientRect();

    const localStyles = {
        outerContainer: {

        },
        container: {
            backgroundColor: "transparent",
            position: "fixed",
            zIndex: 999,
            top: 0,
            left: 0,
            right: 0,
            bottom: 0
        },
        popup: {
            display: "flex",
            userSelect: "none",
            backgroundColor: theme.colors.white,
            borderColor: theme.colors.lightBorder,
            borderWidth: 1,
            borderStyle: "solid",
            borderRadius: 8,
            position: "absolute",
            minWidth: 160,
            minHeight: 100,
            top: targetDomNode.y + targetDomNode.height + OFFSET,
            left: targetDomNode.x - 10,
            boxShadow: theme.colors.bigShadow
        },
        pointer: {
            position: "absolute",
            top: -14,
            left: 10
        }
    };

    const hide = (evt) => {
        evt.stopPropagation();
        onHide()
    };

    return (
            <div style={localStyles.container} onClick={(evt) => hide(evt)}>
            <div style={localStyles.popup}>
                {renderContent()}
                <div style={localStyles.pointer}>
                    <SvgSpeechPointer
                        borderColor={theme.colors.lightBorder}
                        backgroundColor={theme.colors.white}
                    />
                </div>
                
            </div>
        </div>  
    );

};