import React from "react";
import useColorScheme from "../hooks/useColorScheme";
import Themes from "../Themes";
import Button from "./Button";

export default({message, callToActionLabel, onCallToAction, loading}) => {

    const theme = Themes[useColorScheme()];

    const localStyles = {
        container: {
            display: "flex",
            flexDirection: "column"
        },
        buttonHolder: {
            marginTop: 20
        }
    };

    return (
        <div style={localStyles.container}>
            <div style={theme.styles.placeholder}>{message}</div>

            <div style={localStyles.buttonHolder}>
                <Button
                    label={callToActionLabel}
                    width={200}
                    onClick={onCallToAction}
                    loading={loading}
                />
            </div>

        </div>
    );
};