import React, {useState} from "react";
import useColorScheme from "../../hooks/useColorScheme";
import Themes from "../../Themes";
import SvgColorPicker from "../svgs/ColorPicker";
import html2canvas from "html2canvas";
import ColorPickerOverlay from "../ColorPickerOverlay";

export default({color, onChangeColor}) => {
    const theme = Themes[useColorScheme()];

    const [picking, setPicking] = useState(false);
    const [canvas, setCanvas] = useState(null);

    const localStyles = {
        container: {
            display: "flex",
            flexDirection: "column",
            width: "100%"
        },
        content: {
            display: "flex",
            flex: 1
        },
        colorPicker: {
            color: picking ? theme.colors.primary : theme.colors.border,
            cursor: "pointer"
        },
        bottomBar: {
            borderTopColor: theme.colors.lightBorder,
            borderTopWidth: 1,
            borderTopStyle: "solid",
            height: 30,
            width: "100%",
            display: "flex",
            alignItems: "center",
        },
        bottomBarInner: {
            paddingLeft: 10,
            paddingRight: 10
        }
    }

    const startPicking = async (evt) => {
        evt.stopPropagation();
        
        

        const canvas = await html2canvas(document.body, {
            allowTaint: true,
            width: window.innerWidth,
            height: window.innerHeight
        });

        canvas.width = window.innerWidth;
        canvas.height = window.innerHeight;

        setCanvas(canvas);
        setPicking(true);
    }

    const onPickColor = (x, y) => {

        if (canvas) {
            var ctx = canvas.getContext('2d');
            var p = ctx.getImageData(Math.floor(x), Math.floor(y), 1, 1).data; 
            console.log(canvas.toDataURL());
            console.log(p[0], p[1], p[2]);
        }
        
    
    
    };

    return (
        <div style={localStyles.container}>
            <div style={localStyles.content}>

            </div>
            <div style={localStyles.bottomBar}>
                <div style={localStyles.bottomBarInner}>
                    <div style={localStyles.colorPicker} onClick={(evt) => startPicking(evt)}>
                        <SvgColorPicker/>
                    </div>
                </div>
            </div>
            <ColorPickerOverlay
                active={picking}
                canvas={canvas}
                onClose={() => setPicking(false)}
                onPick={(x, y) => onPickColor(x, y)}
            />
        </div>
    );
};