import React, { useState } from "react";
import SceneCard from "./SceneCard";
import {useHistory} from "react-router-dom";
import Placeholder from "./Placeholder";
import SceneServices from "../services/SceneServices";

export default({scenes, project, exampleData}) => {

    const history = useHistory();
    const [adding, setAdding] = useState(false);

    const localStyles = {
        scenes: {
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap"
        }
    };
    
    const onGoToScene = (scene) => {
        history.push(`/dashboard/projects/${project.id}/scenes/${scene.id}`);
    };

    const onAddScene = async () => {
        setAdding(true);

        const scene = await SceneServices.createScene(project.id);
        history.push(`/dashboard/projects/${project.id}/scenes/${scene.id}`);
    };

    if (!scenes) {
        return null;
    }

    if (scenes.length === 0) {
        return (
            <Placeholder
                message="🔦 Lights. 📽 Camera. Why don't you add some action 🎬."
                callToActionLabel="Add a scene"
                onCallToAction={() => onAddScene()}
                loading={adding}
            />
        );
    }

    return (
        <div style={localStyles.scenes}>
            {scenes.map((scene) => {
                return (
                    <SceneCard
                        key={scene.id}
                        scene={scene}
                        exampleData={exampleData}
                        project={project}
                        onClick={() => onGoToScene(scene)}
                    />
                )
            })}
        </div>
    );
};