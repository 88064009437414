import * as React from "react";

function SvgPlayButton(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 26"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M23.5 13c0 5.75-15.08 14.329-20 11.5C1.032 23.081.91 18.033.873 13 .836 7.999.869 3.013 3.5 1.5c5.279-3.035 20 5.75 20 11.5z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgPlayButton;

