import React from "react";
import useColorScheme from "../../hooks/useColorScheme";
import Themes from "../../Themes";

export default({object, onChange, property, value}) => {
    const theme = Themes[useColorScheme()];

    const change = (val) => {
        if (!property.parse) {
            onChange(val);
        } else {
            // Parse first
            onChange(property.parse(val));
        }
    };

    return (
        <input 
            type="text"
            style={theme.styles.propertyInput}
            value={value}
            placeholder={property.placeholder}
            onChange={(evt) => change(evt.target.value)}
            />
    );
};