import React from "react";
import useColorScheme from "../hooks/useColorScheme";
import ComponentServices from "../services/ComponentServices";
import Themes from "../Themes";
import AlignmentProperty from "./properties/AlignmentProperty";
import ColorProperty from "./properties/ColorProperty";
import TextProperty from "./properties/TextProperty";
import UrlProperty from "./properties/UrlProperty";

export default({property, object, onChangeObject}) => {

    const theme = Themes[useColorScheme()];

    const localStyles = {
        container: {
            display: "flex",
            flexDirection: "column",
            marginBottom: 10
        }
    };

    const onChange = (val) => {
        const updatedObj = ComponentServices.setPropertyValueOnObject(object, property.id, val);
        onChangeObject(updatedObj);

        // var updatedObject = Object.assign({}, object);
        // updatedObject[property.id] = val;
        // onChangeObject(updatedObject);
    };

    const getValue = () => {
        const valueFromObj = ComponentServices.getPropertyValueFromObject(object, property.id);

        if (valueFromObj) {
            return valueFromObj;
        }

        return "";
    }

    const renderContent = () => {
        const value = getValue();

        if (property.type === ComponentServices.PROPERTY_TYPES.text) {
            return (
                <TextProperty
                    property={property}
                    onChange={onChange}
                    object={object}
                    value={value}
                />
            );
        } else if (property.type === ComponentServices.PROPERTY_TYPES.url) {
            return (
                <UrlProperty
                    property={property}
                    onChange={onChange}
                    object={object}
                    value={value}
                />
            );
        } else if (property.type === ComponentServices.PROPERTY_TYPES.color) {
            return (
                <ColorProperty
                    property={property}
                    onChange={onChange}
                    object={object}
                    value={value}
                />
            );
        } else if (property.type === ComponentServices.PROPERTY_TYPES.alignment) {
            return (
                <AlignmentProperty
                    property={property}
                    onChange={onChange}
                    object={object}
                    value={value}
                />
            )
        }

        return null;
    };

    return (
        <div style={localStyles.container}>
            <div style={theme.styles.propertyTitle}>{property.title}</div>
            {renderContent()}
        </div>
        
    );
};