import React, { useState } from "react";
import Themes from "../Themes";

import useColorScheme from "../hooks/useColorScheme";
import TextBox from "../components/TextBox";
import Button from "../components/Button";
import HttpServices from "../services/HttpServices";

export default () => {
    const theme = Themes[useColorScheme()];
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const localStyles = {
        logoBox: {
            width: 60,
            height: 60,
            marginBottom: 50
        },
        container: {
            display: "flex",
            flex: 1,
            flexDirection: "column",
            justifyContent: "space-between"
        }
    };

    const doLogin = async () => {
        if (email.length > 0 && password.length > 0) {
            try {
                await HttpServices.login(email, password);
                window.location.href = "/dashboard";
            } catch (e) {
                // Need to do something about error
            }
        }
            
    };

    return (
        <div style={theme.styles.centeredBox}>
            <div style={localStyles.container}>
                <img 
                    alt="Logo"
                    src={theme.images.userflixBox}
                    style={localStyles.logoBox}/>
                <TextBox
                    label="Email"
                    placeholder="Email"
                    autoCapitalize="none"
                    autoCorrect="none"
                    onChange={(val) => setEmail(val)}
                />
                <TextBox
                    label="Password"
                    type="password"
                    placeholder="Password"
                    onChange={(val) => setPassword(val)}
                />
                <Button
                    label="Login"
                    onClick={() => doLogin()}
                />
            </div>
            
            
        </div>
    );
};