export default {

    getNestedValueFromObject(obj, key) {
        var s = key;
        var o = Object.assign({}, obj);
        
        s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
        s = s.replace(/^\./, '');           // strip a leading dot
        var a = s.split('.');
        for (var i = 0, n = a.length; i < n; ++i) {
            var k = a[i];
            if (k in o) {
                o = o[k];
            } else {
                return;
            }
        }
        return o;
    },

    substituteParam(param, userData) {
        if (userData[param]) {
            return userData[param];
        }

        if (param.indexOf(".") >= 0) {
            const val = this.getNestedValueFromObject(userData, param);
            if (val) {
                return val;
            }
        }

        // Here we return a blank string, not null so str processing still works
        return "";
    },

    makeSubstitutions(value, userData) {
        console.log("Make substitutions", value, userData);
       if (!value) {
           return null;
       } 

       if (!userData) {
           return value;
       }

       var str = "";
       var inParam = false;
       var currentParam = "";

       for (var i = 0; i < value.length; i++) {
            if (value.charAt(i) === "{") {
                inParam = true; 
                currentParam = "";
            } else if (value.charAt(i) === "}") {
                inParam = false;
                
                // See if we have a subsititution
                str += this.substituteParam(currentParam, userData);
                currentParam = "";
            } else {
                // Not at start or end

                if (inParam) {
                    currentParam += value.charAt(i);
                } else {
                    str += value.charAt(i);
                }
            }
        }

        return str;

    }

};