import React from "react";
import useColorScheme from "../hooks/useColorScheme";
import Themes from "../Themes";

export default({label, visible}) => {

    if (!visible) {
        return null;
    }

    const theme = Themes[useColorScheme()];

    const localStyles = {
        container: {
            position: "absolute",
            zIndex: 999,
            bottom: 4,
            padding: 5,
            borderRadius: 4,
            backgroundColor: theme.colors.black,
            userSelect: "none"
        },
        label: {
            color: theme.colors.white,
            fontWeight: theme.weights.bold,
            fontSize: 11,
            userSelect: "none"
        }
    };

    return (
        <div style={localStyles.container}>
            <div style={localStyles.label}>{label}</div>
        </div>
    )
};