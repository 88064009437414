import * as React from "react";

function SvgAddMenuBar(props) {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 27 27"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.5 0A2.5 2.5 0 0116 2.5v8.499l8.5.001a2.5 2.5 0 110 5l-8.5-.001V24.5a2.5 2.5 0 11-5 0v-8.501L2.5 16a2.5 2.5 0 110-5l8.5-.001V2.5A2.5 2.5 0 0113.5 0z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgAddMenuBar;