import React from "react";

export default({object, resizingTransform}) => {

    var width = resizingTransform && resizingTransform.width ? resizingTransform.width : object.style.width;
    var height = resizingTransform && resizingTransform.height ? resizingTransform.height : object.style.height;

    if (object.style.borderWidth) {
        width = width - (object.style.borderWidth * 2);
        height = height - (object.style.borderWidth * 2);
    }

    const localStyles = {
        container: {
            cursor: "pointer",
            position: "absolute",
            height: height,
            width: width,
            borderRadius: object.style.borderRadius,
            backgroundColor: object.style.backgroundColor,
            borderColor: object.style.borderColor,
            borderWidth: object.style.borderWidth,
            borderStyle: object.style.borderWidth ? "solid" : null
        }
    };        

    return (
        <div style={localStyles.container}>

        </div>
    );
};