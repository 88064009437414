import React from "react";
import ResizeHandle from "./ResizeHandle";

export default({children, object, readOnly, selected, position, screenSize, transform, resizingTransform, onDragStart, onSelect, onBeginResize, onEndResize}) => {

    const getCoordinateWithResizeTransform = (value, fieldName) => {
        if (!resizingTransform || !resizingTransform[fieldName]) {
            return value;
        }

        return resizingTransform[fieldName];
    };

    const localStyles = {
        container: {
            position: "absolute",
            top: getCoordinateWithResizeTransform(position.y, "y"),
            left: getCoordinateWithResizeTransform(position.x, "x"),
            width: getCoordinateWithResizeTransform(object.style.width, "width"),
            height: getCoordinateWithResizeTransform(object.style.height, "height"),
            transform: transform ? `translate3d(${transform.x / screenSize.zoom}px, ${transform.y / screenSize.zoom}px, 0)` : null
        }
    };

    const onClick = (evt) => {
        if (!readOnly) {
            evt.stopPropagation();
            onSelect();
        }
    };

    const handles = [
        {id: "nw", top: 0, left: 0, cursor: "nwse-resize", onBeginResize: (evt) => onBeginResize({top: true, left: true, startX: evt.clientX, startY: evt.clientY}), onEndResize: () => onEndResize()},
        {id: "ne", top: 0, right: 0, cursor: "nesw-resize",  onBeginResize: (evt) => onBeginResize({top: true, right: true, startX: evt.clientX, startY: evt.clientY}), onEndResize: () => onEndResize()},
        {id: "sw", bottom: 0, left: 0, cursor: "nesw-resize",  onBeginResize: (evt) => onBeginResize({bottom: true, left: true, startX: evt.clientX, startY: evt.clientY}), onEndResize: () => onEndResize()},
        {id: "se", bottom: 0, right: 0, cursor: "nwse-resize",  onBeginResize: (evt) => onBeginResize({bottom: true, right: true, startX: evt.clientX, startY: evt.clientY}), onEndResize: () => onEndResize()}
    ];

    return (
        <div onClick={(evt) => onClick(evt)} onMouseDown={(evt) => onDragStart(evt)} style={localStyles.container}>
            {children}
            
            {handles.map((handle) => {
                return (
                    <ResizeHandle
                        key={handle.id}
                        visible={selected}
                        top={handle.top}
                        left={handle.left}
                        bottom={handle.bottom}
                        right={handle.right}
                        cursor={handle.cursor}
                        onBeginResize={handle.onBeginResize}
                        onEndResize={handle.onEndResize}
                    />
                );
            })}
            
        </div>
    );
};