import * as React from "react";

function SvgDuplicate(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 25 24"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <defs>
        <rect
          id="duplicate_svg__a"
          x={6.471}
          y={5.667}
          width={18.12}
          height={17.891}
          rx={3.9}
        />
        <mask
          id="duplicate_svg__b"
          maskContentUnits="userSpaceOnUse"
          maskUnits="objectBoundingBox"
          x={0}
          y={0}
          width={18.12}
          height={17.891}
          fill="#fff"
        >
          <use xlinkHref="#duplicate_svg__a" />
        </mask>
      </defs>
      <g stroke="currentColor" fill="none" fillRule="evenodd">
        <use
          mask="url(#duplicate_svg__b)"
          strokeWidth={5.2}
          strokeDasharray="11.7,1.3"
          xlinkHref="#duplicate_svg__a"
        />
        <rect
          strokeWidth={3.12}
          x={1.56}
          y={1.56}
          width={14.998}
          height={14.769}
          rx={4.68}
        />
      </g>
    </svg>
  );
}

export default SvgDuplicate;

