import React from "react";

export default({children, zoom, addingObject, onChangeAddingObject, onAddedObject}) => {

    const localStyles = {
        container: {
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            cursor: "crosshair",
            zIndex: 999
        },
        adding: {
            position: "absolute",
            //top: addingObject && addingObject.position ? addingObject.position.y : 0,
            left: addingObject && addingObject.position ? addingObject.position.x : undefined,
            top: addingObject && addingObject.position ? addingObject.position.y : undefined
        }
    };

    const onMouseDown = (evt) => {
        evt.preventDefault();
        evt.stopPropagation();

        if (addingObject) {
            const rect = evt.target.getBoundingClientRect();
            const x = (evt.clientX - rect.left) / zoom;
            const y = (evt.clientY - rect.top) / zoom;

            var updatedObj = Object.assign({}, addingObject);
            
            // We use initial point so we can calculate which direction to draw in
            updatedObj.initialPoint = {
                x: x,
                y: y
            };

            // We also update the object's point, so we begin to see the object
            updatedObj.position = {
                x: x,
                y: y
            }

            //updatedObj.style.width = 1;
            //updatedObj.style.height = 1;
            
            onChangeAddingObject(updatedObj);

        }
    };

    const resizeAddedObject = (evt) => {
        evt.stopPropagation();
        evt.preventDefault();

        var updatedObj = Object.assign({}, addingObject);
            
        var x = 0;
        var y = 0;
        var width = 1;
        var height = 1;

        const rect = evt.target.getBoundingClientRect();
        const mouseX = (evt.clientX - rect.left) / zoom;
        const mouseY = (evt.clientY - rect.top) / zoom; 

        // Calc X
        if (mouseX < addingObject.initialPoint.x) {
            x = mouseX;
            width = addingObject.initialPoint.x - mouseX;
        } else {
            x = addingObject.initialPoint.x;
            width = mouseX - addingObject.initialPoint.x;
        }

        // Calc Y
        if (mouseY < addingObject.initialPoint.y) {
            y = mouseY;
            height = addingObject.initialPoint.y - mouseY;
        } else {
            y = addingObject.initialPoint.y;
            height = mouseY - addingObject.initialPoint.y;
        }

        // We also update the object's point, so we begin to see the object
        updatedObj.position = {
            x: x,
            y: y
        }

        updatedObj.style.width = width;
        updatedObj.style.height = height;
        
        onChangeAddingObject(updatedObj);
    }

    const onMouseMove = (evt) => {
        evt.stopPropagation();
        evt.preventDefault();

        if (addingObject && addingObject.initialPoint) {
            // We are adding object/dragging out a new size
            resizeAddedObject(evt);
        }
    };

    return (
        <div 
            style={localStyles.container} 
            onMouseDown={(evt) => onMouseDown(evt)} 
            onMouseMove={(evt) => onMouseMove(evt)}
            onMouseUp={(evt) => onAddedObject}>
            
        </div>
    );
};