export default {
    standard(x) {
        return x;
    },
    
    easeInCubic(x) {
        return x * x * x;
    },

    easeInQuad(x) {
        return x * x;
    },

    easeInSine(x) {
        return 1 - Math.cos((x * Math.PI) / 2);
    }
};