import React from "react";
import MenuBarIcon from "../images/icons/MenuBarIcon";
import MenuBarSeparator from "./MenuBarSeparator";

export default({selectedObject, onMoveBackward, onMoveForward, onDelete, onDuplicate}) => {
    if (!selectedObject) {
        return null;
    }

    const localStyles = {
        container: {
            display: "flex",
            alignItems: "center"
        }
    }

    return (
        <div style={localStyles.container}>
            <MenuBarSeparator/>
            <MenuBarIcon
                type="delete"
                onClick={onDelete}
            />
            <MenuBarIcon
                type="moveToBack"
                onClick={onMoveBackward}
            />
            <MenuBarIcon
                type="moveToFront"
                onClick={onMoveForward}
            />
            <MenuBarIcon
                type="duplicate"
                onClick={onDuplicate}
            />
        </div>
    );
};