import * as React from "react";

function SvgSpeechPointer(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 18 15"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path stroke={props.borderColor} d="M.937 14l8-12.304 8 12.304" />
        <path fill={props.backgroundColor} d="M1.366 14.275L8.941 2.591l7.575 11.684z" />
      </g>
    </svg>
  );
}

export default SvgSpeechPointer;