import React from "react";
import useColorScheme from "../../hooks/useColorScheme";
import Themes from "../../Themes";

const ALIGNMENTS = [
    {id: 1, alignment: "flex-start", value: "left"},
    {id: 2, alignment: "center", value: "center"},
    {id: 3, alignment: "flex-end", value: "right"},
    
];

const LINES = [
    {id: 1, width: 8},
    {id: 2, width: 12},
    {id: 3, width: 8}
];

export default ({object, onChange, property, value}) => {
    const theme = Themes[useColorScheme()];

    const localStyles = {
        container: {
            display: "flex",
            flexDirection: "row"
        },
        option: {
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: 22,
            height: 22,
            borderColor: theme.colors.lightBorder,
            borderRadius: 2,
            borderWidth: 1,
            borderStyle: "solid",
            fontSize: 10,
            marginRight: 5
        },
        innerContainer: {
            display: "flex",
            height: 12,
            width: 12,
            alignItems: "center",
            justifyContent: "space-around",
            flexDirection: "column",
            flex: 1
        },
        line: {
            height: 2,
            borderRadius: 2,
            backgroundColor: theme.colors.border
        }
    };
    
    return (
        <div style={localStyles.container}>
            {ALIGNMENTS.map((alignment) => {
                var innerStyle = Object.assign({}, localStyles.innerContainer);
                innerStyle.alignItems = alignment.alignment;

                var optionStyles = Object.assign({}, localStyles.option);

                const isSelected =  value && alignment.value === value
                
                // if (isSelected) {
                //     optionStyles.backgroundColor = theme.colors.lightBorder;
                // }

                return (
                    <div key={alignment.id} style={optionStyles} onClick={() => onChange(alignment.value)}>
                        <div style={innerStyle}>
                            {LINES.map((line) => {
                                var style = Object.assign({}, localStyles.line);

                                style.width = line.width;
                                
                                if (isSelected) {
                                    style.backgroundColor = theme.colors.black;
                                }

                                return (
                                    <div key={line.id} style={style}></div>
                                );
                            })}
                        </div>
                    </div>
                );
            })}
            
            
            
        </div>
    );
};