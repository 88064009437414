import React from "react";

import {
    BrowserRouter as Router,
    Switch,
    Route,
  } from "react-router-dom";

  import Placeholder from "./Placeholder";
  import Dashboard from "./screens/Dashboard";
  import Login from "./screens/Login";

  export default function App() {
    return (
      <Router>
        <div>
          <Switch>
            <Route path="/dashboard">
              <Dashboard/>
            </Route>
            <Route path="/login">
              <Login/>
            </Route>
            <Route path="/">
              <Placeholder />
            </Route>
          </Switch>
        </div>
      </Router>
    );
  }