import React from "react";
import SubstitutionServices from "../../services/SubstitutionServices";

export default({object, resizingTransform, exampleData}) => {

    const localStyles = {
        container: {
            cursor: "pointer",
            position: "absolute",
            height: resizingTransform && resizingTransform.height ? resizingTransform.height : object.style.height,
            width: resizingTransform && resizingTransform.width ? resizingTransform.width : object.style.width,
            color: object.style.color,
            backgroundColor: object.style.backgroundColor,
            textAlign: object.style.textAlign,
            fontSize: object.style.fontSize,
            fontFamily: object.style.fontFamily,
            fontWeight: object.style.fontWeight,
            userSelect: "none !important",
            overflow: "hidden",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: object.style.textAlign === "center" ? "center" : (object.style.textAlign === "left" ? "flex-start" : "flex-end")
        },
        overlay: {
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 999,
            userSelect: "none",
            backgroundColor: "rgba(255,255,255,0)"
        }
    };        

    const renderOverlay = () => {
        return (
            <div style={localStyles.overlay}></div>
        );
    };

    return (
        <div style={localStyles.container} className="no-select">
            {SubstitutionServices.makeSubstitutions(object.value, exampleData)}
            {renderOverlay()}
        </div>
    );
};