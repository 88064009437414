import { useState, useEffect } from 'react';

const LIGHT = "light";
const DARK = "dark";

export default() => {
    const [mode, setMode] = useState(LIGHT);

    useEffect(() => {
        var initialMode = LIGHT;

        if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
            // dark mode
            initialMode = DARK;
        }

        setMode(initialMode);

        window.matchMedia("(prefers-color-scheme: dark)").addEventListener("change",  (e) => {
            if (e.matches) {
                setMode(DARK);
            } else {
                setMode(LIGHT);
            }

        })

    }, []);

    return mode;
}