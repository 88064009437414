import React from "react";
import useColorScheme from "../hooks/useColorScheme";
import ComponentServices from "../services/ComponentServices";
import Themes from "../Themes";
import Property from "./Property";

export default({selectedObject, onChangeObject}) => {

    const theme = Themes[useColorScheme()];

    const localStyles = {
        container: {
            position: "absolute",
            top: 1,
            bottom: 0,
            left: 0,
            width: 220,
            backgroundColor: theme.colors.white,
            borderRightColor: theme.colors.lightBorder,
            borderRightWidth: 1,
            borderRightStyle: "solid"
        },
        innerContainer: {
            padding: 30
        },
        propertyTitle: {
            fontSize: 14
        },
        properties: {
            paddingTop: 10
        }
    };

    const renderContent = () => {
        if (!selectedObject) {
            return null;
        }

        const objectType = ComponentServices.TYPES[selectedObject.type];

        return (
            <div style={localStyles.innerContainer}>
                <div style={theme.styles.smallHeading}>{objectType.title}</div>
                <div style={localStyles.properties}>
                    {objectType.properties.map((property) => {
                        return (
                            <Property
                                key={property.id}
                                property={property}
                                object={selectedObject}
                                onChangeObject={onChangeObject}
                            />
                        );
                    })}
                </div>
                
            
            </div>
        );
    };

    return (
        <div style={localStyles.container}>
            {renderContent()}
        </div>
    );
};