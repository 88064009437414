import * as React from "react";

function SvgUserflixBox(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 900 900"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <rect fill="transparent" width={900} height={900} rx={154} />
        <path fill={props.color} d="M252 350h133v101H252z" />
        <path
          d="M518.482 486.919H651c-2.9 53.702-19.436 94.563-49.61 122.583C568.01 640.501 518.154 656 451.825 656c-66.762 0-116.834-15.5-150.215-46.498-30.174-28.02-46.71-68.88-49.61-122.583h133.168c1.342 20.871 6.87 36.912 16.585 48.122 11.272 13.006 27.962 19.51 50.072 19.51 22.11 0 38.8-6.504 50.072-19.51 9.715-11.21 15.243-27.25 16.585-48.122zM650.812 245v241.918H518.154V245h132.658z"
          fill={props.color}
        />
        <path
          d="M258.437 276.297l43.135 41.626L257.7 328l-9.7-49.306 10.437-2.397zm54.621-12.544l40.481 42.236-32.376 7.435-43.135-41.626 35.03-8.045zM377.3 249l9.7 49.306-13.583 3.118-40.482-42.236L377.3 249z"
          fill={props.color}
        />
      </g>
    </svg>
  );
}

export default SvgUserflixBox;