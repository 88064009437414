import React from "react";
import useColorScheme from "../hooks/useColorScheme";
import Themes from "../Themes";

import {
    useHistory
  } from "react-router-dom";
  
import MenuBarIcon from "../images/icons/MenuBarIcon";
import SelectedObjectMenuBar from "./SelectedObjectMenuBar";
import MenuBarSeparator from "./MenuBarSeparator";

const DEFAULT_FILL_COLOR = "#D0CFCF";
const DEFAULT_BORDER_COLOR = "#ffffff";

export default({title, type, onAdd, onPlay, onChangeExampleData, onTitleClick, onMoveForward, onMoveBackward, selectedObject, onDelete, onDuplicate}) => {

    const theme = Themes[useColorScheme()];
    const history = useHistory();

    const localStyles = {
        icon: {
            width: 40,
            height: 40,
            cursor: "pointer"
        },
        container: {
            position: "fixed",
            display: "flex",
            alignItems: "center",
            top: 0,
            left: 0,
            right: 0,
            height: 80,
            backgroundColor: theme.colors.white,
            borderBottomWidth: 1,
            borderBottomColor: theme.colors.lightBorder,
            //boxShadow: theme.colors.shadow,
            borderBottomStyle: "solid",
            paddingLeft: 30,
            paddingRight: 30
        },
        titleAndSubtitle: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            paddingLeft: 20,
            paddingRight: 50,
            marginRight: 20,
            borderRightStyle: "solid",
            borderRightWidth: 1,
            borderRightColor: theme.colors.lightBorder,
            height: 40
        },
        headerTitle: {
            fontSize: 18,
            fontWeight: theme.weights.bold,
            cursor: onTitleClick ? "pointer" : null
        },
        icons: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: 80
        }
    };

    const goHome = () => {
        history.push(`/dashboard`);
    };

    const getDefaultFillColor = () => {
        return DEFAULT_FILL_COLOR;
    };

    const getDefaultBorderColor = () => {
        return DEFAULT_BORDER_COLOR;
    };

    const renderIcons = () => {
        if (!type) {
            return null;
        }

        if (type === "project") {
            return (
                <div style={localStyles.icons}>
                    <MenuBarIcon
                        type="play"
                        onClick={() => onPlay()}
                    />
                    <MenuBarIcon
                        type="json"
                        onClick={onChangeExampleData}
                    />
                </div>
            )
        } else if (type === "scene") {
            return (
                <div style={localStyles.icons}>
                    <MenuBarIcon
                        type="add"
                    />
                    <MenuBarIcon
                        type="square"
                        onClick={() => onAdd({
                            type: "rectangle", 
                            style: {
                                backgroundColor: getDefaultFillColor(),
                                borderColor: getDefaultBorderColor(),
                                borderWidth: 1,
                                borderStyle: "solid"
                            }
                        })}
                    />
                    <MenuBarIcon
                        type="circle"
                        onClick={() => onAdd({
                            type: "circle", 
                            style: {
                                backgroundColor: getDefaultFillColor(),
                                borderColor: getDefaultBorderColor(),
                                borderWidth: 1,
                                borderStyle: "solid"
                            }
                        })}
                    />
                    <MenuBarIcon
                        type="image"
                        onClick={() => onAdd({
                            type: "image", 
                            style: {
                                backgroundColor: getDefaultFillColor()
                            }
                        })}
                    />
                    <MenuBarIcon
                        type="text"
                        onClick={() => onAdd({
                            type: "text", 
                            style: {
                                fontSize: 30,
                                fontWeight: 300,
                                color: getDefaultFillColor(),
                                width: 1,
                                height: 1
                            },
                            value: "Enter text"
                        })}
                    />
                    <SelectedObjectMenuBar
                        selectedObject={selectedObject}
                        onDelete={onDelete}
                        onDuplicate={onDuplicate}
                        onMoveForward={onMoveForward}
                        onMoveBackward={onMoveBackward}
                    />
                    <MenuBarSeparator/>
                    <MenuBarIcon
                        type="play"
                        onClick={() => onPlay()}
                    />
                    <MenuBarIcon
                        type="json"
                        onClick={onChangeExampleData}
                    />
                </div>
            );
        }

        return null;
    };

    return (
        <div style={localStyles.container}>
            <img
                alt="Logo"
                src={theme.images.userflixBox}
                style={localStyles.icon}
                onClick={() => goHome()}/>
            <div style={localStyles.titleAndSubtitle}>
                <div style={localStyles.headerTitle} onClick={onTitleClick}>{title}</div>
            </div>
            {renderIcons()}
        </div>
    );
};