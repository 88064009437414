import React, { useState } from "react";
import useColorScheme from "../../hooks/useColorScheme";
import Themes from "../../Themes";
import ColorPopup from "./ColorPopup";
import PropertyPopup from "./PropertyPopup";

export default({object, value, onChange, property}) => {
    const theme = Themes[useColorScheme()];
    const [showPopup, setShowPopup] = useState(false);

    const localStyles = {
        container: {
            display: "flex",
            flexDirection: "row"
        },
        colorInput: {
            borderWidth: 1,
            borderRadius: 2,
            width: 80,
            borderStyle: "solid",
            borderColor: theme.colors.lightBorder,
            color: theme.colors.black,
            outline: "none",
            padding: 5
        },
        colorSquare: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginRight: 5,
            width: 26,
            height: 26,
            borderRadius: 2,
            borderWidth: 1,
            borderStyle: "solid",
            cursor: "pointer",
            borderColor: theme.colors.lightBorder,
        },
        innerColor: {
            width: 18,
            height: 18,
            borderRadius: 2,
            backgroundColor: value
        }
    };

    const togglePopup = (evt) => {
        setShowPopup(evt);
    }; 

    return (
        <div style={localStyles.container}>
            <div style={localStyles.colorSquare} onClick={(evt) => togglePopup(evt)}>
                <div style={localStyles.innerColor}></div>
            </div>
            <input 
                type="text" 
                value={value}
                onChange={(evt) => onChange(evt.target.value)}
                style={localStyles.colorInput}/>
            <PropertyPopup
                onHide={() => setShowPopup(null)}
                visible={showPopup}
                renderContent={() => 
                    <ColorPopup 
                        color={value} 
                        onChange={(newValue) => onChange(newValue)}
                    />
                }
            />
        </div>
    );
};