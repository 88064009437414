import React, { useEffect, useState } from "react";
import SectionTitle from "../components/SectionTitle";
import Loader from "../components/Loader";
import ProjectServices from "../services/ProjectServices";
import Themes from "../Themes";
import useColorScheme from "../hooks/useColorScheme";
import ProjectCard from "../components/ProjectCard";

import {useHistory} from "react-router-dom";
import Placeholder from "../components/Placeholder";
import HeaderBar from "../components/HeaderBar";

export default() => {

    const theme = Themes[useColorScheme()];
    const history = useHistory();

    const [projects, setProjects] = useState(null);
    const [loading, setLoading] = useState(true);

    const localStyles = {
        projects: {
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap"
        }
    };

    useEffect(() => {
        reload();
    }, []);

    const reload = async () => {
        setLoading(true);
        const loadedProjects = await ProjectServices.listProjects();
        setProjects(loadedProjects);
        setLoading(false);
    };

    const onGoToProject = (project) => {
        history.push(`/dashboard/projects/${project.id}`);
    };

    const onShowAdd = () => {
        history.push(`/dashboard/projects/new`);
    };

    const renderContent = () => {
        if (loading || !projects) {
            return (
                <Loader/>
            );
        }

        if (projects.length === 0) {
            return (
                <Placeholder
                    message="👋 Hello. You don't have any projects yet"
                    callToActionLabel="Create Project"
                    onCallToAction={() => onShowAdd()}
                />
            )
        }

        return (
            <div style={localStyles.projects}>
                {projects.map((project) => {
                    return (
                        <ProjectCard
                            key={project.id}
                            project={project}
                            onClick={() => onGoToProject(project)}
                        />
                    )
                })}
            </div>
        );
    };

    return (
        <div>
            <HeaderBar
                title="Projects"
            />
            
            <div style={theme.styles.mainContent}>
                <SectionTitle 
                    title="Projects"
                    onAdd={() => onShowAdd()}                
                />
                {renderContent()}
            </div>
            
        </div>
    );
};