import React from "react";
import useColorScheme from "../hooks/useColorScheme";
import Themes from "../Themes";

export default({children, onClick}) => {
    const theme = Themes[useColorScheme()];

    return (
        <div style={theme.styles.card} onClick={onClick}>
            {children}
        </div>
    );

};  