import React, { useEffect, useState } from "react";
import useColorScheme from "../hooks/useColorScheme";
import Themes from "../Themes";
import SvgDeleteButton from "../images/icons/SvgDeleteButton";
import TimelineServices from "../services/TimelineServices";
import useInterval from "../hooks/useInterval";
import ScenePreviewPlayArea from "./ScenePreviewPlayArea";

export default({playing, scene, project, objects, data, exampleData, onClose, onSceneEnd}) => {
    const [timeline, setTimeline] = useState(null);
    const [loading, setLoading] = useState(true);
    const [frameNumber, setFrameNumber] = useState(0);
    const [finished, setFinished] = useState(false);

    useEffect(() => {
        if (playing) {
            setFinished(false);
            setFrameNumber(0);
            reloadTimeline();
        }
    }, [playing, scene]);

    useInterval(() => {
        if (frameNumber + 1 === timeline.length) {
            if (onSceneEnd) {
                onSceneEnd();
            } else {
                setFinished(true);
            }
        } else {
            setFrameNumber(frameNumber + 1);
        }
    }, playing && !finished &&  timeline && frameNumber <= timeline.length ? (1000 / TimelineServices.FPS) : null)

    const reloadTimeline = () => {
        setLoading(true);
        const createdTimeline = TimelineServices.createTimeline(scene, data);
        setTimeline(createdTimeline);
        setLoading(false);
    };

    const theme = Themes[useColorScheme()];
    const localStyles = {
        container: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: theme.colors.alwaysBlack
        },
        playArea: {
            position: "relative",
            width: project.width,
            height: project.height
        },
        closeHolder: {
            position: "absolute",
            top: 40,
            right: 40,
            color: theme.colors.white,
            cursor: "pointer"
        }
    };

    if (!playing || loading) {
        return null;
    }


    return (
        <div style={localStyles.container}>
            <div style={localStyles.closeHolder} onClick={onClose}>
                <SvgDeleteButton
                    width={20}
                    height={20}
                />
            </div>
            <div style={localStyles.playArea}>
                <ScenePreviewPlayArea
                    project={project}
                    frameNumber={frameNumber}
                    timeline={timeline}
                    objects={objects}
                    exampleData={exampleData}
                />
            </div>
        </div>
    );
};