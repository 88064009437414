import React from "react";
import SubstitutionServices from "../../services/SubstitutionServices";

export default({object, resizingTransform, exampleData}) => {

    const getBorderRadius = () => {
        if (resizingTransform && resizingTransform.width && resizingTransform.height) {
            if (resizingTransform.height > resizingTransform.width) {
                return resizingTransform.height / 2;
            } else {
                return resizingTransform.width / 2;
            }
        }

        if (object.style.height > object.style.width) {
            return object.style.height / 2;
        } else {
            return object.style.width / 2;
        }
    };

    var width = resizingTransform && resizingTransform.width ? resizingTransform.width : object.style.width;
    var height = resizingTransform && resizingTransform.height ? resizingTransform.height : object.style.height;

    if (object.style.borderWidth) {
        width = width - (object.style.borderWidth * 2);
        height = height - (object.style.borderWidth * 2);
    }

    const localStyles = {
        container: {
            cursor: "pointer",
            position: "absolute",
            height: height,
            width: width,
            borderRadius: getBorderRadius(),
            backgroundColor: SubstitutionServices.makeSubstitutions(object.style.backgroundColor, exampleData),
            borderColor: object.style.borderColor,
            borderWidth: object.style.borderWidth,
            borderStyle: object.style.borderWidth ? "solid" : null
        }
    };        

    return (
        <div style={localStyles.container}>

        </div>
    );
};