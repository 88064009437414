import * as React from "react";

function SvgImage(props) {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18 0a6 6 0 016 6v12a6 6 0 01-6 6H6a6 6 0 01-6-6V6a6 6 0 016-6h12zm-5.071 9l-3.376 6.266-2.056-3.293L3.397 21h18L12.928 9zM5.5 4a2.5 2.5 0 100 5 2.5 2.5 0 000-5z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgImage;

