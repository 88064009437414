import * as React from "react";

function SvgAddSquareButton(props) {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x={370}
        y={46}
        width={24}
        height={24}
        rx={6}
        transform="translate(-370 -46)"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgAddSquareButton;
