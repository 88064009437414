import React, { useState } from "react";
import Tooltip from "../../components/Tooltip";
import useColorScheme from "../../hooks/useColorScheme";
import Themes from "../../Themes";

import Add from "./SvgAddMenuBar";
import Square from "./SvgAddSquareButton";
import Circle from "./SvgAddCircleButton";
import Image from "./SvgAddImageButton";
import Delete from "./SvgDeleteButton";
import Text from "./SvgAddTextButton";
import Play from "./SvgPlayButton";
import Stop from "./SvgStopButton";
import MoveToFront from "./SvgMoveToFrontButton";
import MoveToBack from "./SvgMoveToBackButton";
import JsonButton from "./SvgJsonButton";
import Duplicate from "./SvgDuplicateButton";

const DEFAULT_WIDTH = 20;
const DEFAULT_HEIGHT = 20;

export default({color, type, width, height, onClick}) => {

    const [over, setOver] = useState(false);

    const ICONS = {
        add: {
            render: () => <Add width={width || DEFAULT_WIDTH} height={height || DEFAULT_HEIGHT}/>,
            tooltip: "Add.."
        },
        square: {
            render: () => <Square width={width || DEFAULT_WIDTH} height={height || DEFAULT_HEIGHT}/>,
            tooltip: "Square"
        },
        circle: {
            render: () => <Circle width={width || DEFAULT_WIDTH} height={height || DEFAULT_HEIGHT}/>,
            tooltip: "Circle"
        },
        image: {
            render: () => <Image width={width || DEFAULT_WIDTH} height={height || DEFAULT_HEIGHT}/>,
            tooltip: "Image"
        },
        text: {
            render: () => <Text width={width || DEFAULT_WIDTH} height={height || DEFAULT_HEIGHT}/>,
            tooltip: "Text"
        },
        json: {
            render: () => <JsonButton width={width || DEFAULT_WIDTH} height={height || DEFAULT_HEIGHT}/>,
            tooltip: "Example Data"
        },
        delete: {
            render: () => <Delete width={width || DEFAULT_WIDTH} height={height || DEFAULT_HEIGHT}/>,
            tooltip: "Delete"
        },
        moveToFront: {
            render: () => <MoveToFront width={width || DEFAULT_WIDTH} height={height || DEFAULT_HEIGHT}/>,
            tooltip: "Move forwards"
        },
        moveToBack: {
            render: () => <MoveToBack width={width || DEFAULT_WIDTH} height={height || DEFAULT_HEIGHT}/>,
            tooltip: "Move backwards"
        },
        duplicate: {
            render: () => <Duplicate width={width || DEFAULT_WIDTH} height={height || DEFAULT_HEIGHT}/>,
            tooltip: "Duplicate"
        },
        play: {
            render: () => <Play width={width || DEFAULT_WIDTH} height={height || DEFAULT_HEIGHT}/>,
            tooltip: "Play"
        },
        stop: {
            render: () => <Stop width={width || DEFAULT_WIDTH} height={height || DEFAULT_HEIGHT}/>,
            tooltip: "Stop"
        },
        
    };

    if (!ICONS[type]) {
        return null;
    }

    const theme = Themes[useColorScheme()];

    const getColor = () => {
        var colorToUse = color || theme.colors.border;

        if (over) {
            colorToUse = theme.colors.primary
        }

        return colorToUse;
    };

    const localStyles = {
        container: {
            color: getColor(),
            cursor: "pointer",
            transition: "all 0.1s ease-in-out",
            marginRight: 15
        }
    };

    return (
        <div style={localStyles.container} onMouseOver={() => setOver(true)} onMouseOut={() => setOver(false)} onClick={onClick}>
            {ICONS[type].render()}
            <Tooltip
                visible={over}
                label={ICONS[type].tooltip}
            />
        </div>
    );
};