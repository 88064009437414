import * as React from "react";

function SvgColorPicker(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 31 31"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path
          d="M25.21 5.27a2.54 2.54 0 010 3.598l-2.474 2.475-.101.093c.54.788 1.168 1.771 1.885 2.948.493.809.368 1.85-.301 2.52l-.16.158a1.828 1.828 0 01-2.584 0l-7.98-7.979a1.828 1.828 0 010-2.585l.16-.16c.669-.669 1.71-.793 2.518-.3a69.093 69.093 0 012.859 1.823c.032-.04.068-.078.106-.116l2.475-2.475a2.54 2.54 0 013.598 0z"
          fill="currentColor"
        />
        <path
          d="M19.882 10.676a3.47 3.47 0 011.02 2.46c0 .89-.34 1.781-1.02 2.461h0L11.1 24.379a3.47 3.47 0 01-2.46 1.02 3.47 3.47 0 01-2.462-1.02 3.47 3.47 0 01-1.019-2.46c0-.891.34-1.782 1.02-2.461h0l8.782-8.782a3.47 3.47 0 012.46-1.02c.89 0 1.782.34 2.461 1.02h0z"
          stroke="currentColor"
          strokeWidth={1.5}
        />
        <path
          fill="currentColor"
          d="M4.693 21.155L9.53 25.99l-2.048-.266-2.754-2.754z"
        />
      </g>
    </svg>
  );
}

export default SvgColorPicker;