import EasingServices from "./EasingServices";

const ANIMATION_TYPES = {
    grow: {
        id: "grow",
        title: "Grow",
        preview: {
            animation: {
                scale: 2
            },
            transition: {
                repeat: "Infinity",
                duration: 1,
                repeatDelay: 0.2
            },
            interpolate: (position, progress) => {

            }
        }
        
    },
    fadeIn: {
        id: "fadeIn",
        title: "Fade In",
        preview: {
            animation: {
                opacity: 0
            },
            initial: {
                opacity: 1
            },
            exit: {
                opacity: 1
            },
            transition: {
                repeat: "Infinity",
                duration: 1,
                repeatDelay: 0.2
            }
        },
        interpolate: (position, progress, duration) => {
            var opacity = EasingServices.easeInSine(progress / duration);

            return {
                x: position.x,
                y: position.y,
                opacity: opacity
            }
        }

    },
    fadeOut: {
        id: "fadeOut",
        title: "Fade Out",
        preview: {
            animation: {
                opacity: 0
            },
            initial: {
                opacity: 1
            },
            exit: {
                opacity: 1
            },
            transition: {
                repeat: "Infinity",
                duration: 2,
                repeatDelay: 0.2
            }
        },
        interpolate: (position, progress, duration) => {
            var opacity = 1 - EasingServices.easeInSine(progress / duration);

            return {
                x: position.x,
                y: position.y,
                opacity: opacity
            }
        }

    },
    fromLeft: {
        id: "fromLeft",
        title: "From Left",
        preview: {
            animation: {
                transform: "translateX(100%)"
            },
            initial: {
                transform: "translateX(-100%)"
            },
            exit: {
                transform: "translateX(-100%)"
            },
            transition: {
                repeat: "Infinity",
                duration: 1,
                repeatDelay: 0.2
            }
        },
        interpolate: (position, progress, duration) => {
            var x = (position.x * EasingServices.easeInSine(progress / duration));
        
            if (x > position.x) {
                x = position.x;
            }

            return {
                x: x,
                y: position.y
            }
        }
    },
    toLeft: {
        id: "toLeft",
        title: "To Left",
        preview: {
            animation: {
                transform: "translateX(100%)"
            },
            initial: {
                transform: "translateX(-100%)"
            },
            exit: {
                transform: "translateX(-100%)"
            },
            transition: {
                repeat: "Infinity",
                duration: 1,
                repeatDelay: 0.2
            }
        },
        interpolate: (position, progress, duration) => {
            var x = position.x - (position.x * EasingServices.easeInSine(progress / duration));
        
            if (x > position.x) {
                x = position.x;
            }

            return {
                x: x,
                y: position.y
            }
        }
    },
    fromTop: {
        id: "fromTop",
        title: "From Top",
        preview: {
            animation: {
                transform: "translateY(100%)"
            },
            initial: {
                transform: "translateY(-100%)"
            },
            exit: {
                transform: "translateY(-100%)"
            },
            transition: {
                repeat: "Infinity",
                duration: 1,
                repeatDelay: 0.2
            }
        },
        interpolate: (position, progress, duration) => {
            var y = position.y * EasingServices.standard(progress / duration);
        
            if (y > position.y) {
                y = position.y;
            }

            return {
                x: position.x,
                y: y
            }
        }
    },
    fromRight: {
        id: "fromRight",
        title: "From Right",
        preview: {
            animation: {
                transform: "translateX(-100%)"
            },
            initial: {
                transform: "translateX(100%)"
            },
            exit: {
                transform: "translateX(100%)"
            },
            transition: {
                repeat: "Infinity",
                duration: 1,
                repeatDelay: 0.2
            },
            interpolate: (position, progress) => {
                
            }
        }
    }
};

export default {
    ANIMATION_TYPES: ANIMATION_TYPES,
    APPEAR_ANIMATIONS: [ANIMATION_TYPES.grow, ANIMATION_TYPES.fadeIn, ANIMATION_TYPES.fromTop, ANIMATION_TYPES.fromLeft],
    LEAVE_ANIMATIONS: [ANIMATION_TYPES.grow, ANIMATION_TYPES.fadeOut, ANIMATION_TYPES.fromRight]
};