import * as React from "react";

function SvgStopButton(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17 0a7 7 0 017 7v10a7 7 0 01-7 7H7a7 7 0 01-7-7V7a7 7 0 017-7h10zM9 6a2 2 0 00-2 2v7a2 2 0 004 0V8a2 2 0 00-2-2zm6 0a2 2 0 00-2 2v7a2 2 0 004 0V8a2 2 0 00-2-2z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgStopButton;

