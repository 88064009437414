import React, { useState } from "react";
import useColorScheme from "../../hooks/useColorScheme";
import Themes from "../../Themes";
import PropertyPopup from "./PropertyPopup";

export default({label, value, onChange, renderContent}) => { 

    const theme = Themes[useColorScheme()];

    const [showDropDown, setShowDropDown] = useState(null);

    const localStyles = {
        container: {
            display: "flex",
            alignItems: "center",
            paddingLeft: 10,
            paddingRight: 10,
            height: 30,
            borderWidth: 1,
            borderStyle: "solid",
            borderColor: theme.colors.lightBorder
        },
        value: {
            flex: 1,
            fontSize: 12,
            color: theme.colors.black,
            fontWeight: theme.weights.regular,
            cursor: "pointer",
            userSelect: "none"
        }
    };

    return (
        <div style={localStyles.container}>
            <div style={localStyles.value} onClick={(evt) => setShowDropDown(evt)}>
                {value}
            </div>
            <PropertyPopup
                onHide={() => setShowDropDown(null)}
                visible={showDropDown}
                renderContent={renderContent}
            />
        </div>
    )

}   