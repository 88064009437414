import React from "react";
import useColorScheme from "../hooks/useColorScheme";
import Themes from "../Themes";

export default({label, type, placeholder, onChange, autoCorrect, autoCapitalize}) => {
    const theme = Themes[useColorScheme()];

    const localStyles = {
        container: {
            display: "flex",
            flexDirection: "column",
            marginBottom: 30,
        },
        textBox: {
            marginTop: 10,
            borderStyle: "none",
            borderBottomWidth: 1,
            paddingBottom: 10,
            borderBottomColor: theme.colors.border,
            borderBottomStyle: "solid",
            outline: "none",
            fontSize: 18,
            borderRadius: 0,
            color: theme.colors.black,
            fontWeight: theme.weights.bold
        }
    };

    return (
        <div style={localStyles.container}>
        <div style={theme.styles.label}>{label}</div>
            <input 
                type={type || "text"} 
                autoCapitalize={autoCapitalize}
                autoCorrect={autoCorrect}
                placeholder={placeholder}
                style={localStyles.textBox}
                onChange={(evt) => onChange(evt.target.value)}/>
        </div>
    );
};