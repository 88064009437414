import React from "react";
import useColorScheme from "../hooks/useColorScheme";
import Themes from "../Themes";
import Button from "../components/Button";

export default({visible, title, minWidth, width, height, renderContent, buttons, onHide}) => {
    const theme = Themes[useColorScheme()];

    if (!visible) {
        return null;
    }

    const localStyles = {
        container: {
            backgroundColor: theme.colors.white,
            width: width || "60%",
            height: height || "60%",
            minWidth: minWidth || 300,
            borderRadius: 6,
            display: "flex",
            flexDirection: "column",
            boxShadow: theme.colors.bigShadow
        },
        content: {
            paddingLeft: 30,
            paddingRight: 30,
            paddingTop: 20,
            paddingBottom: 20,
            flex: 1,
            overflowY: "auto"
        },
        header: {
            paddingLeft: 30,
            paddingRight: 30,
            paddingTop: 20,
            paddingBottom: 20,
            borderBottomWidth: 1,
            borderBottomStyle: "solid",
            borderBottomColor: theme.colors.lightBorder
        },
        footer: {
            paddingLeft: 30,
            paddingRight: 30,
            paddingTop: 20,
            paddingBottom: 20,
            borderTopWidth: 1,
            borderTopStyle: "solid",
            borderTopColor: theme.colors.lightBorder
        },
        headerTitle: {
            fontWeight: "700",
            fontSize: 18
        },
        rightButtons: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "center"
        },
        leftButtons: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center"
        },
        buttons: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between"
        },
        overlay: {
            position: "fixed",
            zIndex: 999,
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: theme.colors.overlay
        }
    };

    const onOverlayClick = (evt) => {
        if (evt.target.dataset.overlay) {
            onHide();
        } else {
            evt.stopPropagation();
        }
    }

    const renderButtons = (buttons, style) => {
        return (
            <div style={style}>
                {buttons.map((btn) => {
                    return (
                        <Button
                            key={btn.id}
                            disabled={btn.disabled}
                            label={btn.label}
                            width={btn.width}
                            height={btn.height}
                            onClick={btn.onClick}
                        />
                    );
                })}
            </div>
        );
    }

    const renderLeftButtons = () => {
        if (!buttons || !buttons.left) {
            return null;
        }

        return renderButtons(buttons.left, localStyles.leftButtons);
    };

    const renderRightButtons = () => {
        if (!buttons || !buttons.right) {
            return null;
        }

        return renderButtons(buttons.right, localStyles.rightButtons);
    };

    return (
        <div data-overlay={true} style={localStyles.overlay} onMouseDown={(evt) => onOverlayClick(evt)}>
            <div className="modal" style={localStyles.container}>
                <div style={localStyles.header}>
                    <div style={localStyles.headerTitle}>{title}</div>
                </div>
                <div style={localStyles.content}>
                    {renderContent()}
                </div>
                <div style={localStyles.footer}>
                    <div style={localStyles.buttons}>
                        {renderLeftButtons()}
                        {renderRightButtons()}
                    </div>
                </div>
            </div>
        </div>
        
    );
};