import * as React from "react";

function SvgAddTextButton(props) {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18 0a6 6 0 016 6v12a6 6 0 01-6 6H6a6 6 0 01-6-6V6a6 6 0 016-6h12zm-1 7H7v2.015h3.582V17h2.852V9.015H17V7z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgAddTextButton;